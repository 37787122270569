/* Development and local environment */
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --` then `environment.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  envName: 'Development',
  production: true,
  theme: 'alpha',
  title: 'Disp-NgWeb',
  timeout: 5000,
  description: 'Disp-NgWeb',
  // SOR IS NOT A RECO BUT IT CAN BE CONTACTED BY CORS
  RECOs: ['POSTOJNA', 'KOPER', 'KRANJ', 'NOVA-GORICA', 'SOR'],
  baseUrl: 'https://portal-ng-alpsar.nextgen-lab.net/',
  dispNgAuthApi: {
    'baseUrl': 'http://api-alpsar.nextgen-lab.net/authnservice/oauth/token',
  },

  dispNgUserApi: {
    'baseUrl': 'http://api-alpsar.nextgen-lab.net/authnservice/me'
  },
  dispNgEmerApi: {
    //'baseUrl': 'https://api-alpsar.nextgen-lab.net/alpsaremerservice/emer',
    'baseUrl': 'http://api-alpsar.nextgen-lab.net/edgeservice/api/alpsaremerservice/emer',
      //'baseUrl': 'http://localhost:35000/alpsaremerservice/emer',
    'GET': {
      'incidents': '/incidents',
      'incident': '/incidents/$1',
      'messages': '/incidents/$1/messages',
      'message': '/incidents/$1/messages/$2',
      'faxes': '/faxes'
    },
    'PUT': {
      'ack': '/ack',
      'offer': '/offer',
      'request': '/request',
      'confirm': '/confirm'
    },
    'POST':
    {
      'inform': '/inform'
    },
    'CLOSE': {
      'incident': '/close/$1'
    },
  },

  dispNgEmerFaxApi: {
    'baseUrl': 'http://api-alpsar.nextgen-lab.net/alpsaremerfax',
    // 'baseUrl': 'http://pssu-host09.nextgen-lab.net:31076',
    'statusGET': '/RESTserviceFaxStatus', // need id
    'sendPOST': '/RESTserviceFaxSend',
    'previewPOST': '/RESTserviceFaxPreview'
  },

  VERSION: require('../../package.json').version,
  NAME: require('../../package.json').name
};
