import { Component, OnInit, ElementRef, ViewChild, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import OlMap from 'ol/map';
import OlXYZ from 'ol/source/xyz';
import OlTileLayer from 'ol/layer/tile';
import OlView from 'ol/view';
import OlProj from 'ol/proj';
import Draw from 'ol/interaction/draw';
import OlFormatGeoJSON from 'ol/format/geojson';
import OlLoadingStrategy from 'ol/loadingstrategy';
import OlVector from 'ol/source/vector';
import OlLayerVector from 'ol/layer/vector';
import Collection from 'ol/collection';
import { AlertEMER } from '../models/alertEMER';
import { EMERGlobalComponent } from '../emer/emer.component';
import Select from 'ol/interaction/select';
import { Incident } from '../models/incident';
import { EmerService } from '../emer/services/emer.service';

@Component({
  selector: 'app-mapview',
  templateUrl: './mapView.component.html',
  styleUrls: ['./mapView.component.scss']

})

export class MapViewComponent implements OnInit {
  @ViewChild('collapseButton') collapseButton: ElementRef;
  @ViewChild('explorer') explorer: EMERGlobalComponent;

  // see transition time into the scss
  animationTime = 1000;
  features: Collection;
  customClass: String = 'panel-primary';
  vectorSource: OlVector;
  vectorSources = new Array<OlVector>();
  clickInteraction: Select;
  layerVector: OlLayerVector;
  map: OlMap;
  source: OlXYZ;
  layer: OlTileLayer;
  view: OlView;
  draw: Draw;
  // page config
  isCollapsed = true;
  isCollapsedSideBar = false;
  isCollapsedMain = true;
  public alertEmer: AlertEMER;
  constructor(private myElement: ElementRef, private _cfr: ComponentFactoryResolver, public emerService: EmerService) { }

  ngOnInit() {
    this.vectorSource = new OlVector({
      format: new OlFormatGeoJSON(),
      strategy: OlLoadingStrategy.bbox,
      wrapX: false,
      noWrap: true,
    });

    this.layerVector = new OlLayerVector({
      source: this.vectorSource,
    });

    this.source = new OlXYZ({
      // Tiles from Mapbox (Light)
      wrapX: false,
      noWrap: true,
      url: 'https://tileserver.4umaps.com/{z}/{x}/{y}.png?' +
        'access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw'
    });

    this.layer = new OlTileLayer({
      source: this.source
    });

    this.view = new OlView({
      center: OlProj.fromLonLat([14.4188836, 46.0536075]),
      zoom: 9,
      minZoom: 2,
    });

    this.map = new OlMap({
      target: 'map',
      layers: [this.layer, this.layerVector],
      view: this.view,

    });
    this.refresh();
  }
  removeInteraction() { this.map.removeInteraction(this.draw); }

  // END MAP FUNCTION

  fitMapFromCurrentVectorSource() {
    this.map.getView().fit(this.vectorSource.getExtent(), this.map.getSize());
  }

  refreshVectorSource(vectorSource) {
    this.removeInteraction();
    // console.log('refreshVectorSource');
    this.vectorSource.clear();
    this.clearMap();

    this.vectorSource = vectorSource;
    // // Create vector layer attached to the vector source.
    this.layerVector = new OlLayerVector({
      source: vectorSource,
    });
    for (const layer of this.map.getLayers()) {
      this.map.removeLayer(this.layerVector);
    }
    this.map.removeLayer(this.draw);
    // // Add the vector layer to the map.
    this.map.addLayer(this.layerVector);
    // this.map.getView().fit(this.vectorSource.getExtent(), this.map.getSize());
    // // ALLOW CLICK ON MAP
    this.clickInteraction = new Select();
    this.map.addInteraction(this.clickInteraction);
    const myThis = this;

    this.clickInteraction.on('select', function (e) {
      const feature: OlVector = e.selected[0];
      const incident: Incident = feature.get('incident');
      const alertEMER: AlertEMER = feature.get('alertEMER');
      if (incident) {
        myThis.emerService.getAlertsByIncident(incident.incidentId).subscribe(alertsEmer => {
          e.target.getFeatures().clear();
          const status = incident.status;
          let view;
          (status !== 'RESOURCES_REQUESTED' && status !== 'RESOURCES_OFFERED') ?
            view = 'emer1View' : view = 'emer2View';
          myThis.openExplorer({
            view: view,
            incident: incident,
            alert: alertsEmer[0]
          });
        });
      }
    });
  }


  // SIDE BAR ANNIMATION FUNCTIONS
  collapseSideBar() {
    this.refresh();
    this.isCollapsedSideBar = !this.isCollapsedSideBar;
    this.isCollapsedMain = !this.isCollapsedMain;
  }

  openSideBar() {
    if (this.isCollapsedSideBar) {
      this.refresh();
      this.isCollapsedSideBar = !this.isCollapsedSideBar;
      this.isCollapsedMain = !this.isCollapsedMain;
    }
  }

  closeSideBar() {
    if (this.isCollapsedSideBar) {
      this.refresh();
      this.isCollapsedSideBar = this.isCollapsedSideBar;
      this.isCollapsedMain = this.isCollapsedMain;
    }
  }

  refreshMapEndCollapseButton() {
    this.map.updateSize();
    const textButtonElement = this.collapseButton.nativeElement;
    this.isCollapsedSideBar ? textButtonElement.innerText = '<' : textButtonElement.innerText = '>';
  }
  // END SIDE BAR ANNIMATION FUNCTIONS
  // MAP FUNCTION
  refresh() {
    setTimeout(() => this.refreshMapEndCollapseButton(), this.animationTime);
  }

  openExplorer(msg) {
    this.alertEmer = msg.alert;
    // console.log('fromopenexplorer');

    const vector = this.emerService.alertToVectorSource(this.alertEmer, msg.incident);
    if (this.alertEmer && msg.incident) {
      this.refreshVectorSource(vector);
    }
    this.explorer.openExplorer(msg.view, msg.incident, msg.alert);
    this.refreshMapEndCollapseButton();
    this.openSideBar();
  }

  addInteraction = function (selectedOption) {
    // console.log(selectedOption);
    this.map.render();
    if (selectedOption !== 'None') {
      if (this.draw) {
        this.map.removeInteraction(this.draw);
      } if (this.clickInteraction) {
        this.map.removeInteraction(this.clickInteraction);
      }
      this.draw = new Draw({
        source: this.vectorSource,
        type: selectedOption
      });
      this.draw.on('drawend',
      function(evt) {
        // console.log(evt.target.source_);
        // console.log(evt.feature);
        this.explorer.validateCoordinate(evt.feature);
        this.map.removeInteraction(this.draw);

      },
    this);
      this.map.addInteraction(this.draw);
    }
  }

  clearMap = function () {
    this.vectorSource.clear();
  }
}
