import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';

import { User } from '../models/user';
import { Observable } from 'rxjs/Rx';
import { AuthRes } from '../models/authRes';
import { Router } from '@angular/router';
import { LocalizeRouterService } from 'localize-router';
import { environment } from '../../environments/environment';
import {AlertEMER, RECIPIENT} from '../models/alertEMER';

// Import RxJs required methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {Incident, MessageReferences} from '../models/incident';

const dispNgAuthApi = environment.dispNgAuthApi.baseUrl;
const dispNgUserApi = environment.dispNgUserApi.baseUrl;

@Injectable()
export class AuthService {
    isAuthenticated = false;
    userId;
    windowHandle;
    ourcode;
    accesstoken;

    constructor(private http: HttpClient, private router: Router, private localize: LocalizeRouterService) { }

    loginAndAuthorizeUser(usercreds): Observable<User> {
        const client_id = 'acme';
        const client_secret = 'acmesecret';
        const basicheader = btoa(client_id + ':' + client_secret);
        const tokendata = 'grant_type=password&client_id=acme&scope=openid&username=' + usercreds.username +
            '&password=' + usercreds.password;
        return this.http.post(dispNgAuthApi, tokendata, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic ' + basicheader
            },
        })
            .map((res: AuthRes) => {
                return this.loginWithToken(res.access_token)
                    .subscribe((user) => {
                        if (user !== null) {
                            // console.log('get USER AND SET');
                            // console.log(user);
                        }
                        // console.log(res);
                        return res;
                    })
            }).catch(this.handleError);
    }

    loginWithToken(res): Observable<User> {
        // console.log(res.access_token);
        this.setAccessToken(res);
        return this.getUserDetails(res);

    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error);
    }

    private urlBase64Decode(str: string) {
        let output = str.replace(/-/g, '+').replace(/_/g, '/');
        switch (output.length % 4) {
            case 0:
                break;
            case 2:
                output += '==';
                break;
            case 3:
                output += '=';
                break;
            default:
                // tslint:disable-next-line:no-string-throw
                throw 'Illegal base64url string!';
        }
        return decodeURIComponent((<any>window).escape(window.atob(output)));
    }

    public decodeToken(token: String = '') {
        if (token === null || token === '') { return { 'upn': '' }; }
        const parts = token.split('.');
        if (parts.length !== 3) {

            throw new Error('JWT must have 3 parts');
        }
        const decoded = this.urlBase64Decode(parts[1]);
        if (!decoded) {
            throw new Error('Cannot decode the token');
        }
        return JSON.parse(decoded);
    }

    // curl -H "Authorization: Bearer $TOKEN" localhost:9999/uaa/user2  | jq '.'
    getUserDetails(access_token): Observable<User> {
        const httpHeaders = new HttpHeaders()
            .set('Authorization', 'Bearer ' + access_token);
        return this.http.get(dispNgUserApi, {
            headers: httpHeaders
        })
            .map((res) => {
                // console.log(res);
                window.sessionStorage.setItem('User', JSON.stringify(this.removeWrongOrganisationUser(res)));
                return res;
            })
            .catch((error: any) => Observable.throw(error || 'Server error'));
    }

    removeAccessToken() {
        // console.log('TOKEN REMOVED');
        window.sessionStorage.removeItem('Token');
    }

    removeUser() { window.sessionStorage.removeItem('User'); }

    logout() {
        this.removeAccessToken();
        this.removeUser();
        const translatedPath: any = this.localize.translateRoute('/login');
        this.router.navigate([translatedPath]);
    }

    setAccessToken(token) {
        // console.log('SET TOKEN: ' + token);

        if (token !== null) {
            window.sessionStorage.setItem('Token', token);
        }
    }

    // keep organisation available
    removeWrongOrganisationUser(user: User) {
        const recipients: String[] = Object.keys(RECIPIENT);
        for (const orga of user.organizations) {
            if (recipients.indexOf(orga) === -1) {
                user.organizations.splice(user.organizations.indexOf(orga), 1);
            }
        }
        return user;
    }

    getUser(): User {

        const user = <User>JSON.parse(window.sessionStorage.getItem('User'));

        if (!user) {
            if (window.sessionStorage.getItem('Token') != null) {
                this.getUserDetails(window.sessionStorage.getItem('Token')).subscribe((userData) => {
                    window.sessionStorage.setItem('User', JSON.stringify(this.removeWrongOrganisationUser(userData)));
                    return this.getUser();
                }
                );
            };
        } else {
            return user;
        }

    }

    getAccessToken() {
        if (window.sessionStorage.getItem('Token') != null) {
            return window.sessionStorage.getItem('Token');
        } else {
            return null;
        }
    }

    getOperator(author: string): string {
        let profile = this.getUser();
        return (author != undefined ? author.toUpperCase() : 'unknownAuthor') + '-operator ' + profile.uid.toLowerCase();
    }


    getOriginalCreator(incident: Incident, alertEMER: AlertEMER): String {
        try {
            let originalCreator: String = '';
            if(incident != undefined && incident.messageReferences &&  incident.messageReferences.length > 0) {
                for(let messageReference of incident.messageReferences) {
                    if(messageReference.status == 'EVENT_TYPE_CIS_INFORM') {
                        originalCreator = messageReference.sender;
                        break;
                    }
                }
            }
            if(originalCreator) {
                if(incident == undefined || (incident && (!incident.status || incident.status == 'CREATED'))) {
                    if(alertEMER.alert && alertEMER.alert.author)
                        originalCreator = alertEMER.alert.author;
                    else
                        originalCreator = this.getUser().organizations[0];
                }
                else if(alertEMER.reference && alertEMER.reference.incident) {
                    let array: String[] = alertEMER.reference.incident.split(',');
                    if (array.length > 0) {
                        originalCreator = array[0];
                    } else {
                        originalCreator = alertEMER.reference.incident;
                    }
                }
            }
            return originalCreator.toUpperCase();
        } catch (ex) {
            console.error(ex);
        };
        return null;
    }
}
