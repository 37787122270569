import { Alert, CustomProperty, CERTAINTY, SEVERITY, URGENCY, CATEGORY_BY_EVENTTYPE } from './alert';
import { Area, PolygonEMER, CircleEMER } from './area';

export enum RESOURCE {
    PERSONNEL = 'PERSONNEL',
    VEHICLES = 'VEHICLES',
    FOOD = 'FOOD',
    TENTS = 'TENTS',
    HELI = 'HELI',
    MEETING_POINT = 'MEETING_POINT',
    OFFICER = 'OFFICER',
    INTERPRETER = 'INTERPRETER',
    RADIO_CHANEL = 'RADIO_CHANEL',
    NOTES = 'NOTES',
    OPERATOR = 'OPERATOR'
}

export enum RECIPIENT {
    'CORS' = 'CORS',
    'SOR' = 'SOR',
    'POSTOJNA' = 'POSTOJNA',
    'KOPER' = 'KOPER',
    'KRANJ' = 'KRANJ',
    'NOVA-GORICA' = 'NOVA-GORICA',
}

export enum EVENTTYPE {
    Landslide = 'Landslide',
    Flood = 'Flood',
    Earthquake = 'Earthquake',
    ForestFire = 'Forest Fire',
    Other = 'Other',
}

export enum EVENT_TYPE_COLOR {
    Landslide = '0,100,0',
    Flood = '255,140,0',
    Earthquake = '0,0,205',
    ForestFire = '255,0,0',
    Other = '45,42,38',
}

interface IResources {
    capabilities?: string[],
    number?: number,
    type?: RESOURCE
}

// see https://stackoverflow.com/questions/12702548/constructor-overload-in-typescript
export class Resource {
    public capabilities?: string[];
    public number?: number;
    public type?: RESOURCE;
    constructor(obj: IResources);
    constructor(obj?: any) {
        if (obj && obj.capabilities) {
            this.capabilities = obj.capabilities
        }
        if (obj && obj.number) {
            this.number = obj.number
        }
        if (obj && obj.type) {
            this.type = obj.type
        }
    }
}

interface IReference {
    msgRefence?: string,
    incident?: string
}

// see https://stackoverflow.com/questions/12702548/constructor-overload-in-typescript
export class Reference {
    public msgRefence?: string;
    public incident?: string
    constructor(obj: IReference);
    constructor(obj?: any) {
        if (obj && obj.msgRefence) {
            this.msgRefence = obj.msgRefence
        }
        if (obj && obj.incident) {
            this.incident = obj.incident
        }
    }
}

interface IAlertEMER {
    key?: string,
    reply?: string,
    recipients?: RECIPIENT[],
    alert?: Alert,
    area?: Area,
    reference?: Reference, // optional
    resources?: Resource[], // optional
}

// see https://stackoverflow.com/questions/12702548/constructor-overload-in-typescript
export class AlertEMER {
    public key?: string = null;
    public reply?: string = null;
    public recipients?: RECIPIENT[] = new Array<RECIPIENT>();
    public alert?: Alert = new Alert({
        author: '',
        category: CATEGORY_BY_EVENTTYPE.Other,
        certainty: CERTAINTY.UNKNOWN_CERTAINTY,
        customProperties: new Array<CustomProperty>(
            { key: 'city', value: '' },
            { key: 'locality', value: '' },
            { key: 'other', value: '' },
            { key: 'operator', value: '' }
        ),
        description: '',
        eventType: '',
        headline: '',
        severity: SEVERITY.UNKNOWN_SEVERITY,
        status: 'BROKEN',
        urgency: URGENCY.UNKNOWN_URGENCY,
    });
    public area?: Area = new Area({
        polygons: new Array<PolygonEMER>(),
        circles: new Array<CircleEMER>()
    });
    public reference?: Reference; // optional
    public resources?: Resource[] = new Array(); // optional
    constructor(obj: IAlertEMER);
    constructor(obj?: any) {
        if (obj && obj.key) {
            this.key = obj.key
        }
        if (obj && obj.reply) {
            this.reply = obj.reply
        }
        if (obj && obj.recipients) {
            this.recipients = obj.recipients
        }
        if (obj && obj.alert) {
            this.alert = new Alert(obj.alert);
        }
        if (obj && obj.area) {
            this.area = new Area(obj.area);
        }
        if (obj && obj.reference) {
            this.reference = new Reference(obj.reference);
        }

        if (obj && obj.resources) {
            this.resources = obj.resources;
        }
    }

    // public getResources(key: string) {
    //     if (!this.resources.find(obj => obj.type === key)) {
    //         return new Resource({ capabilities: new Array<string>(), number: 0, type: RESOURCE[key] })
    //     } else {
    //         return this.resources.find(obj => obj.type === key);
    //     }
    // }

    public getResources(type: RESOURCE) {
        if (!this.resources.find(obj => obj.type === type)) {

            const resource = new Resource({ capabilities: new Array<string>(), number: 0, type: type });
            this.resources.push(resource);
            return new Array(resource);

        } else {
            return this.resources.filter(obj => obj.type === type);
        }
    }

    public getLatCenter() {
        if (this.area.polygons) {
            return this.area.polygons[0].coordinates[0].lat.toString();
        } else if (this.area.circles) {
            return this.area.circles[0].center.lat;
        }
    }
    public getLngCenter() {
        if (this.area.polygons) {
            return this.area.polygons[0].coordinates[0].lng.toString();
        } else if (this.area.circles) {
            return this.area.circles[0].center.lng;
        }
    }

    public getNumberOfResourceByName(name): number {
        let cpt = 0;
        const resourcesSelected = this.resources.filter(obj => obj.type === RESOURCE[name]);
        for (const resource of resourcesSelected) {
            resource.number ? cpt += resource.number : cpt = cpt;

        }
        return cpt;
    }

    public removeEmptyRessources() {
        if (this.resources) {
            while (this.resources.findIndex(item => (item.number === 0 || item.number === null || !item.number)) > -1) {
                this.resources.splice(this.resources.findIndex(item => (item.number === 0 || item.number === null || !item.number)), 1)
            }
            if (this.resources.length === 0) {
                delete this['resources'];
            }
        }
    }

    public removeEmptyCustomProperties() {
        if (this.alert.customProperties) {
            while (this.alert.customProperties.findIndex(
                item => (item.value === null || !item.value)) > -1) {
                this.alert.customProperties.splice(this.alert.customProperties.findIndex(item => ( item.value === null || !item.value)), 1)
            }
        }
    }
}

