// ANGULAR IMPORTS
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS  } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

// NGX IMPORTS
import { NgSelectModule } from '@ng-select/ng-select';
import { AccordionModule, ModalModule } from 'ngx-bootstrap';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

// CUSTOM COMPONENTS IMPORTS
import { EmerComponent } from './components/emer/emer.component';
import { Emer1Component } from './components/emer1/emer1.component';
import { Emer2Component } from './components/emer2/emer2.component';
import { EmerButtonsComponent } from './components/emerButtons/emerButtons.component';
import { EmerListComponent } from './components/emerList/emerList.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

// CUSTOM SERVICES IMPORTS
import { AuthService } from '../services/auth.service';
import { EmerService } from './services/emer.service';
import { FaxService } from './services/fax.service';
import { MyHttpLogInterceptor } from '../services/errorInterceptor'

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    EmerComponent,
    Emer2Component,
    Emer1Component,
    EmerButtonsComponent,
    EmerListComponent,
  ],
  imports: [
    NgxJsonViewerModule,
    AccordionModule.forRoot(),
    ModalModule.forRoot(),
    NgSelectModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
  ],
  exports: [TranslateModule],
  entryComponents: [Emer1Component, Emer2Component, EmerListComponent],
  providers: [AuthService, EmerService, FaxService, { provide: HTTP_INTERCEPTORS, useClass: MyHttpLogInterceptor, multi: true }],
  bootstrap: []
})
export class EMERModule { }
