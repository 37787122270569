// ANGULAR IMPORTS
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS  } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { Location } from '@angular/common';

// NGX IMPORTS
import { CollapseModule, BsDropdownModule } from 'ngx-bootstrap';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// LOCALIZE AND ROUTING IMPORTS
import { AppRoutingModule, routes } from './app-routing.module';
import { LocalizeRouterModule, LocalizeParser, LocalizeRouterSettings, ManualParserLoader, } from 'localize-router';

// CUSTOM COMPONENTS IMPORTS
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { NavbarComponent } from './navbar/navbar.component';

import { MapViewComponent } from './mapView/mapView.component';
import { ProfileComponent } from './profile/profile.component';
import { ExplorerComponent } from './mapView/explorer/explorer.component';
import { EMERGlobalComponent } from './emer/emer.component';
import { AboutComponent } from './about/about.component';

import { EmerButtonsMapviewComponent } from './emer/components/emerButtonsMapview/emerButtonsMapview.component';

// CUSTOM MODULES IMPORTS
import { EMERModule } from './emer/emer.module';


// CUSTOM SERVICES IMPORTS
import { AuthGuard } from './services/authGuard';
import { AuthService } from './services/auth.service';
import { MyHttpLogInterceptor } from './services/errorInterceptor'

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MapViewComponent,
    EmerButtonsMapviewComponent,
    ProfileComponent,
    AboutComponent,
    ExplorerComponent,
    EMERGlobalComponent,
    NavbarComponent,
  ],
  imports: [
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    EMERModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),

    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: (translate, location, settings) =>
          new ManualParserLoader(translate, location, settings, ['en', 'fr'], null),
        deps: [TranslateService, Location, LocalizeRouterSettings]
      }
    }),
    RouterModule.forRoot(routes)
  ],

  exports: [TranslateModule],

  providers: [AuthService, AuthGuard,     { provide: HTTP_INTERCEPTORS, useClass: MyHttpLogInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
