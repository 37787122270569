import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-btnmapview',
  templateUrl: './emerButtonsMapview.component.html',
  styleUrls: ['./emerButtonsMapview.component.scss']
})
export class EmerButtonsMapviewComponent {
  @Output() public openExplorerFromChild: EventEmitter<object> = new EventEmitter<object>(); // creating an output event
  openExplorer(view) { this.openExplorerFromChild.emit({ view: view, incident: null, alert: null }); }
}
