
export enum CERTAINTY {
    OBSERVED = 'OBSERVED',
    LIKELY = 'LIKELY',
    POSSIBLE = 'POSSIBLE',
    UNLIKELY = 'UNLIKELY',
    UNKNOWN_CERTAINTY = 'UNKNOWN_CERTAINTY'
}
export enum CATEGORY_BY_EVENTTYPE {
    'Landslide' = 'GEO',
    'Flood' = 'MET',
    'Earthquake' = 'GEO',
    'Forest Fire' = 'FIRE',
    'Other' = 'OTHER',
}

export enum SEVERITY {
    EXTREME = 'EXTREME',
    SEVERE = 'SEVERE',
    MODERATE = 'MODERATE',
    MINOR = 'MINOR',
    UNKNOWN_SEVERITY = 'UNKNOWN_SEVERITY'
}

export enum URGENCY {

    IMMEDIATE = 'IMMEDIATE',
    EXPECTED = 'EXPECTED',
    FUTURE = 'FUTURE',
    PAST = 'PAST',
    UNKNOWN_URGENCY = 'UNKNOWN_URGENCY'
}


interface ICustomProperty {
    key: string,
    value: string
}
// see https://stackoverflow.com/questions/12702548/constructor-overload-in-typescript
export class CustomProperty {
    public key: string;
    public value: string;


    constructor(obj: ICustomProperty);
    constructor(obj?: any) {
        if (obj && obj.key) {
            this.key = obj.key
        }
        if (obj && obj.value) {
            this.value = obj.value
        }

    }
}

interface IAlert {
    author?: string;
    eventType?: string;
    category: CATEGORY_BY_EVENTTYPE;
    certainty?: CERTAINTY;
    severity?: SEVERITY;
    urgency?: URGENCY;
    headline?: String;
    status?: 'BROKEN';
    description?: String;
    customProperties?: CustomProperty[];
}
// see https://stackoverflow.com/questions/12702548/constructor-overload-in-typescript
export class Alert {
    public author?: string;
    public eventType = '';
    public category: CATEGORY_BY_EVENTTYPE = CATEGORY_BY_EVENTTYPE.Other;
    public certainty?: CERTAINTY = CERTAINTY.UNKNOWN_CERTAINTY;
    public severity?: SEVERITY = SEVERITY.UNKNOWN_SEVERITY;
    public urgency?: URGENCY = URGENCY.UNKNOWN_URGENCY;
    public headline?: String = '';
    public status?: 'BROKEN';
    public description?: String = '';
    public customProperties?: CustomProperty[] = new Array<CustomProperty>({ key: 'city', value: '' }, { key: 'locality', value: '' }, { key: 'other', value: '' }, { key: 'operator', value: '' });


    constructor(obj: IAlert);
    constructor(obj?: any) {
        if (obj && obj.author) {
            this.author = obj.author
        }
        if (obj && obj.eventType) {
            this.eventType = obj.eventType
        }
        if (obj && obj.category) {
            this.category = obj.category
        }
        if (obj && obj.certainty) {
            this.certainty = obj.certainty
        }
        if (obj && obj.severity) {
            this.severity = obj.severity
        }
        if (obj && obj.urgency) {
            this.urgency = obj.urgency
        }
        if (obj && obj.headline) {
            this.headline = obj.headline
        }
        if (obj && obj.status) {
            this.status = obj.status
        }
        if (obj && obj.description) {
            this.description = obj.description
        }
        if (obj && obj.customProperties) {
            this.customProperties = new Array<CustomProperty>();
            for (const custom of obj.customProperties) {
                this.customProperties.push(new CustomProperty(custom));
            }
        }
    }

    public getcustomProperty(key: string): CustomProperty {
        if (this.customProperties) {
            if (!this.customProperties.find(obj => obj.key === key)) {
                return new CustomProperty({ key: '', value: '' })
            } else {
                return this.customProperties.find(obj => obj.key === key);
            }
        } return new CustomProperty({ key: '', value: '' })
    }
    public getcustomPropertyLike(key: string): CustomProperty[] {
        // console.log("######1");
        // console.log(this.customProperties);
        // console.log(this.customProperties.find(obj => obj.key.includes(key)));
        // console.log(key);
        // console.log("######2");
        if (this.customProperties) {
            if (!this.customProperties.filter(obj => obj.key.includes(key))) {
                return new Array();
            } else {
                return this.customProperties.filter(obj => obj.key.includes(key));
            }
        } return new Array();
    }

    public addCustomPropertyOperator(value) {
        if(this.customProperties) {
            let custom =  { key: 'operator', value: value };
            let customProperty: CustomProperty = this.getcustomProperty(custom.key);
            if(!customProperty.key || customProperty.key == '') {
                this.customProperties.push(new CustomProperty(custom));
            } else {
                customProperty.value = custom.value;
            }
        }
    }
}

