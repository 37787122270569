import { Component, Output, EventEmitter, OnInit, Input, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { AlertEMER } from '../models/alertEMER';
import { Incident } from '../models/incident';
import OlVector from 'ol/source/vector';
import { Emer1Component } from './components/emer1/emer1.component'
import { Emer2Component } from './components/emer2/emer2.component'
import { EmerListComponent } from './components/emerList/emerList.component'
import { LocalizeParser } from 'localize-router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-emer',
  templateUrl: './emer.component.html',
  styleUrls: ['./emer.component.css']
})
export class EMERGlobalComponent implements OnInit {
  @Input() public vectorSource: OlVector;
  @Output() public removeInteractionFromChild: EventEmitter<object> = new EventEmitter<object>();
  @Output() public addDrawInteractionFromChild: EventEmitter<string> = new EventEmitter<string>();
  @Output() public refreshVectorSourceFromChild: EventEmitter<OlVector> = new EventEmitter<OlVector>();
  @Output() public clearMapFromChild: EventEmitter<object> = new EventEmitter<object>();
  @Output() public setTitleFromChild: EventEmitter<string> = new EventEmitter<string>();
  @Output() public fitMapFromCurrentVectorSourceFromChild: EventEmitter<object> = new EventEmitter<object>();
  @ViewChild('sideBarContent', { read: ViewContainerRef }) container: ViewContainerRef;

  _ref: any;
  viewSideBar = {
    'emer1View': { 'title': 'EMER1', },
    'emer2View': { 'title': 'EMER2' },
    'explorerView': { 'title': 'Explorer' }
  }
  public alertsEMER: AlertEMER[];
  public alertsCAP: AlertEMER[];
  public currentAlert: AlertEMER = new AlertEMER({});
  private expComponent = null;

  removeObject() {
    this._ref.destroy();
  }

  constructor(private _cfr: ComponentFactoryResolver, public localize: LocalizeParser, public translate: TranslateService, ) {
    let selectedLanguage = localize.getLocationLang();
    if (!selectedLanguage) { selectedLanguage = localize.defaultLang };

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(selectedLanguage);
    translate.use(selectedLanguage);
  }

  ngOnInit() { this.openExplorer('explorerView', null, null); }

  validateCoordinate(feature) {
    // console.log(vectorSource);
    this.expComponent.instance.validateCoordinate(feature);
  }

  openExplorer(viewName: string, incident: Incident, alertEMER?: AlertEMER) {
    this.clearMap();

    if (this.expComponent) {
      // console.log(this.expComponent.instance);
      this.expComponent.instance.removeObject();
    }
    // console.log('openExplorer from globalemercomponent');
    let comp = null;
    this.expComponent = null;
    let title = this.viewSideBar[viewName].title;
    this.removeInteraction();
    this.container.clear();
    alertEMER == null ? this.currentAlert = null : this.currentAlert = alertEMER;

    if (viewName === 'explorerView') {
      comp = this._cfr.resolveComponentFactory(EmerListComponent);
      this.expComponent = this.container.createComponent(comp);
    } else if (viewName === 'emer1View') {
      comp = this._cfr.resolveComponentFactory(Emer1Component);
      this.expComponent = this.container.createComponent(comp);
    } else if (viewName === 'emer2View') {
      comp = this._cfr.resolveComponentFactory(Emer2Component);
      this.expComponent = this.container.createComponent(comp);
    }

    if (alertEMER && incident) {
      this.expComponent.instance.alertEMER = new AlertEMER(alertEMER);
      title += ' ' + alertEMER.alert.headline + ' - ' + incident.status;
    };
    this.expComponent.instance.openExplorerFromChild.subscribe(msg => { this.openExplorer(msg.view, msg.incident, msg.alert); });
    this.expComponent.instance.refreshVectorSourceFromChild.subscribe(vectorSource => { this.refreshVectorSourceChild(vectorSource); });
    this.expComponent.instance.addDrawInteractionFromChild.subscribe(vectorSource => { this.addDrawInteraction(vectorSource); });
    this.expComponent.instance.removeInteractionFromChild.subscribe(vectorSource => { this.removeInteraction(); });
    this.expComponent.instance.clearMapFromChild.subscribe(vectorSource => { this.clearMap(); });
    this.expComponent.instance.fitMapFromCurrentVectorSourceFromChild.subscribe(msg => { this.fitMapFromCurrentVectorSource(); })
    this.expComponent.instance.vectorSource = this.vectorSource;
    this.expComponent.instance._ref = this.expComponent;

    if (incident) {
      this.expComponent.instance.incident = incident;
    }
    this.setTitleFromChild.emit(title);
  }

  removeInteraction() { this.removeInteractionFromChild.emit(); }
  addDrawInteraction(interaction: string) { this.addDrawInteractionFromChild.emit(interaction); }
  refreshVectorSourceChild(vectorSource) { this.refreshVectorSourceFromChild.emit(vectorSource); }
  clearMap() { this.clearMapFromChild.emit(); }
  fitMapFromCurrentVectorSource() { this.fitMapFromCurrentVectorSourceFromChild.emit() }

}
