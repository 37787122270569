import { Injectable } from '@angular/core';
import { LocalizeRouterService } from 'localize-router';
import { Router } from '@angular/router';

import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse,
    HttpHandler,
    HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

@Injectable()
export class MyHttpLogInterceptor implements HttpInterceptor {
    constructor(private localize: LocalizeRouterService, private router: Router) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // console.log(req.method);
        return next
            .handle(req)
            .do((ev: HttpEvent<any>) => {
                if (ev instanceof HttpResponse) {
                    // console.log('status: ' + ev.status);
                    if (ev.status === 401) {
                        const translatedPath: any = this.localize.translateRoute('/login');
                        this.router.navigate([translatedPath]);
                    }
                }
            })
            .catch(response => {
                if (response instanceof HttpErrorResponse) {
                    // console.log('Processing http error', response);
                    if (response.status === 401) {
                        const translatedPath: any = this.localize.translateRoute('/login');
                        this.router.navigate([translatedPath]);
                    }
                }
                return Observable.throw(response);
            });
    }
}
