import { Component, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import OlVector from 'ol/source/vector';
import { EMERGlobalComponent } from '../../emer/emer.component';

@Component({
  selector: 'app-explorer',
  templateUrl: './explorer.component.html',
  styleUrls: ['./explorer.component.scss']
})

export class ExplorerComponent {
  _ref: any;
  public title = null;

  @ViewChild('emer') emer: EMERGlobalComponent;
  @Input() public vectorSource: OlVector;
  @Output() public removeInteractionFromChild: EventEmitter<object> = new EventEmitter<object>();
  @Output() public addDrawInteractionFromChild: EventEmitter<string> = new EventEmitter<string>();
  @Output() public refreshVectorSourceFromChild: EventEmitter<OlVector> = new EventEmitter<OlVector>();
  @Output() public clearMapFromChild: EventEmitter<object> = new EventEmitter<object>();
  @Output() public fitMapFromCurrentVectorSourceFromChild: EventEmitter<object> = new EventEmitter<object>();

  constructor() { }
  removeObject() {
    this._ref.destroy();
  }

  setTitle(title) { this.title = title }
  removeInteraction() { this.removeInteractionFromChild.emit(); }
  addDrawInteraction(interaction: string) { this.addDrawInteractionFromChild.emit(interaction); }
  refreshVectorSource(vectorSource) {
    this.vectorSource = vectorSource;
    this.refreshVectorSourceFromChild.emit(this.vectorSource);
  }

  fitMapFromCurrentVectorSource() { this.fitMapFromCurrentVectorSourceFromChild.emit(); }
  validateCoordinate(feature) {  this.emer.validateCoordinate(feature) };
  clearMap() { this.clearMapFromChild.emit(); }
  openExplorer(view, incident, alert) {
    this.emer.openExplorer(view, incident, alert);
  }
}
