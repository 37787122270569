interface IArea {
    polygons?: PolygonEMER[],
    circles?: CircleEMER[],
    points?: PointEMER[],
    areaDescription?: String
}
// see https://stackoverflow.com/questions/12702548/constructor-overload-in-typescript
export class Area {
    public polygons?: PolygonEMER[];
    public circles?: CircleEMER[];
    public points?: PointEMER[];
    public areaDescription?: String;


    constructor(obj: IArea);
    constructor(obj?: any) {
        if (obj && obj.polygons) {
            this.polygons = obj.polygons
        }
        if (obj && obj.circles) {
            this.circles = obj.circles
        }
        if (obj && obj.circles) {
            this.circles = obj.circles
        }
        if (obj && obj.areaDescription) {
            this.areaDescription = obj.areaDescription
        }
    }
}

export class CircleEMER {
    constructor(
        public center: Coord,
        public radius: number) { }
}

export class PointEMER {
    constructor(
        public center: Coord,
        public point: Coord,
        public radius: 0) { }
}

export class PolygonEMER {
    constructor(
        public coordinates: Coord[],
        public valid?: true
    ) { }
}

export class Coord {
    constructor(
        public lng: number,
        public lat: number,
        public alt: number,
    ) { }
}

