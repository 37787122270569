import { Component, Output, EventEmitter, Input, ViewChild, TemplateRef, OnInit } from '@angular/core';
import { FaxService } from '../../services/fax.service'
import { AlertEMER, RECIPIENT, RESOURCE, Resource, Reference } from '../../../models/alertEMER'
import {Alert, SEVERITY, CERTAINTY, URGENCY, CATEGORY_BY_EVENTTYPE} from '../../../models/alert'
import { Incident } from '../../../models/incident'


import { EmerService } from '../../services/emer.service'
import { PolygonEMER } from '../../../models/area';
import OlVector from 'ol/source/vector';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../services/auth.service'

@Component({
  selector: 'app-emer2',
  templateUrl: './emer2.component.html',
  styleUrls: ['./emer2.component.scss']
})

export class Emer2Component implements OnInit {
  @ViewChild('template') template: TemplateRef<any>;
  public recipients: String[] = Object.keys(RECIPIENT);
  public _ref: any;
  public resources = Object.keys(RESOURCE);
  public modalRef: BsModalRef;
  public isReco = true;
  public isCreation: Boolean = false;
  public recos = [];
  public isRequested: Boolean = false;
  public isOffer: Boolean = false;
  public isOriginalCreatorCORS: Boolean = false;

  public alertEmerOffer: AlertEMER;

  public alertEmerSelected: AlertEMER;

  @Input() public vectorSource: OlVector;
  @Input() public alertEMER: AlertEMER;
  @Input() public incident: Incident;

  @Output() public refreshVectorSourceFromChild: EventEmitter<OlVector> = new EventEmitter<OlVector>();
  @Output() public addDrawInteractionFromChild: EventEmitter<String> = new EventEmitter<String>();
  @Output() public removeInteractionFromChild: EventEmitter<String> = new EventEmitter<String>();
  @Output() public clearMapFromChild: EventEmitter<String> = new EventEmitter<String>();
  @Output() public openExplorerFromChild: EventEmitter<object> = new EventEmitter<object>();
  @Output() public fitMapFromCurrentVectorSourceFromChild: EventEmitter<any> = new EventEmitter<any>();

  removeObject() { this._ref.destroy(); }

  ngOnInit() {
    this.recos = environment.RECOs;

    this.alertEMER.alert.addCustomPropertyOperator(this.authService.getOperator(this.alertEMER.alert.author));

    if (this.incident.reCo !== 'UNASSIGNED') {
      this.alertEMER.recipients.push(RECIPIENT[this.incident.reCo])
    };

    // create
    if (this.incident.status.indexOf('ACKNOWLEGED') >= 0) {
      this.isCreation = true;
      this.alertEMER['resources'] = new Array();
      for (const resourceGlobal of this.resources) {
        this.addRequestResource(resourceGlobal);
      }
    } else if (this.incident.status === 'RESOURCES_REQUESTED') {
      // acknowledge
      this.isRequested = true;

      this.alertEmerOffer = new AlertEMER(JSON.parse(JSON.stringify(this.alertEMER)));
      this.alertEmerOffer['resources'] = new Array();
      for (const resourceGlobal of this.resources) {
        if(this.alertEMER.getNumberOfResourceByName(resourceGlobal) == 0) {
          this.addOfferResource(resourceGlobal);
        }
      }

    } else if (this.incident.status === 'RESOURCES_OFFERED') {
      // to offer from request
      this.isOffer = true;
    }

    this.isOriginalCreatorCORS = (this.authService.getOriginalCreator(this.incident, this.alertEMER) == 'CORS');

    // this.alertEmerOffer.resources = new Array<Resource>();
    this.isReco = this.emerService.isReco();

    this.alertEmerSelected = this.incident.status === 'RESOURCES_REQUESTED' ? this.alertEmerOffer : this.alertEMER;
  }

  constructor(public faxService: FaxService,
    public emerService: EmerService,
    private modalService: BsModalService,
    public authService: AuthService) {
    // initialize alertEMER attributes
    const alert = new Alert({
      author: '',
      category: CATEGORY_BY_EVENTTYPE.Other,
      certainty: CERTAINTY.UNKNOWN_CERTAINTY,
      customProperties: [
        { key: 'city', value: '' },
        { key: 'locality', value: '' },
        { key: 'other', value: '' },
        { key: 'operator', value: '' }
      ],
      description: '',
      eventType: '',
      headline: '',
      severity: SEVERITY.UNKNOWN_SEVERITY,
      status: 'BROKEN',
      urgency: URGENCY.UNKNOWN_URGENCY,
    });
    const ressourcesArray = new Array<Resource>();
    this.alertEMER = new AlertEMER({
      alert: alert,
      area: {
      },
      key: null,
      recipients: new Array<RECIPIENT>(),
      resources: ressourcesArray
    });

  }

  globalEditable() {
    return this.incident.status === 'ACKNOWLEGED_BY_SOR';
  }
  openModal() {
    this.modalRef = this.modalService.show(this.template);
  }

  closeModal() { this.modalRef.hide(); }

  otherFieldDisabled() {
    return (this.alertEMER.key === null && (this.alertEMER.alert.headline === ''))
      || !this.globalEditable() || (!this.alertEMER.area.polygons && !this.alertEMER.area.circles);
  }
  sendButtonDisabled() {
    // return (this.otherFieldDisabled() && this.alertEMEROffers === null);
    return true;
  }

  isSelectedRecipient(topic) { return this.alertEMER.recipients.indexOf(topic) >= 0; }

  previewFax() {
    // To display alertEMEROffers if it's a response from request
    /*const faxObject = this.faxService.createFaxEMER2Object(this.alertEMER, this.alertEmerOffer);
    if (faxObject !== null) {
      this.openModal();
      this.faxService.preview(faxObject).subscribe(msg => {
        this.closeModal();
        this.faxService.displayAndSavePDF(msg);
      }, (error) => {
      }
      );
    }*/
  }

  sendAlert() {
    // To display alertEMEROffers if it's a response from request
    this.alertEMER.reference = {
      msgRefence: this.alertEMER.reply,
      incident: <string>this.incident.incidentId
    };
    const faxObject = this.faxService.createFaxEMER2Object(this.alertEMER, this.alertEmerOffer);
    if (faxObject !== null) {
      // this.openModal();
      // it is a creation alert

      this.emerService.request(this.alertEMER).subscribe(msg => {
        // this.closeModal();
        this.openExplorerFromChild.emit({ view: 'explorerView', incident: null, alert: null });

        if (alert != null) {
          this.faxService.sendAlertEmer(faxObject).subscribe(success => {
            this.faxService.displayAndSavePDF(success);
            this.close();
          });
        }
      }, (error) => { });
    }
  }
  addRequestResource(resourcename: string) {
    this.alertEMER.resources.push(
      new Resource({ capabilities: new Array<string>(), number: 0, type: RESOURCE[resourcename] })
    );
  }

  addOfferResource(resourcename: string) {
      this.alertEmerOffer.resources.push(
          new Resource({ capabilities: new Array<string>(), number: 0, type: RESOURCE[resourcename] })
      );
  }

  ackAlert() {
    //NOTE#1.2: Keep changed data (according to initial set in NOTE#1.1)
    if (this.alertEmerOffer.alert.author === 'SOR') {
        this.alertEmerOffer.alert.author = this.alertEMER.alert.author;
        this.alertEmerOffer.recipients = this.alertEMER.recipients;
        this.alertEmerOffer.alert.description = this.alertEMER.alert.description;
    }

    // To display alertEMEROffers if it's a response from request
    this.alertEMER = this.alertEmerOffer;
    this.openModal();
    this.alertEmerOffer.reference = {
      msgRefence: this.alertEmerOffer.reply,
      incident: <string>this.incident.incidentId
    };
    const faxObject = this.faxService.createFaxEMER2Object(this.alertEMER, this.alertEmerOffer);

    // it is a ack from request
    this.emerService.offer(this.alertEmerOffer).subscribe(msg => {
      this.faxService.sendAlertEmer(faxObject).subscribe(success => {
        this.closeModal();
        this.faxService.displayAndSavePDF(success);
        this.close();
      });
    }, (error) => { });
  }

  onChangeRecipient(topic: string) {
    this.alertEMER.recipients = this.alertEMER.recipients.filter(function (element) {
      return element !== undefined;
    });
    const index = this.alertEMER.recipients.indexOf(RECIPIENT[topic]);
    (index === -1) ? this.alertEMER.recipients.push(RECIPIENT[topic]) : this.alertEMER.recipients.splice(index, 1);
  }

  onChangeCapabilities(capabilities, capabilitiesNumber) {
      if(capabilitiesNumber !== undefined) {
          if(capabilities != undefined && capabilities != '') {
              //If resource description is filled up the number will be initialized to 1 (if not yet filled up)
              if (!capabilitiesNumber.number || capabilitiesNumber.number == undefined || capabilitiesNumber.number == '')
                  capabilitiesNumber.number = 1;
          } else {
              //If resource description is empty the number will be set to empty
              capabilitiesNumber.number = undefined;
          }
      };
  }

  close() {
    this.removeInteractionChild();
    this.clearMap();
    this.openExplorerFromChild.emit({ view: 'explorerView', incident: null, alert: this.alertEMER });
  }

  addDrawInteractionChild(interactionName) { this.addDrawInteractionFromChild.emit(interactionName); }
  removeInteractionChild() { this.removeInteractionFromChild.emit(); }
  clearMap() { this.clearMapFromChild.emit(); }
  refreshVectorSourceChild(vectorSource) {
    this.refreshVectorSourceFromChild.emit(vectorSource);
    this.fitMapFromCurrentVectorSourceFromChild.emit();
  }


  confirmAlert() {
    this.alertEMER.reference = {
      msgRefence: this.alertEMER.reply,
      incident: this.incident.incidentId
    };
    this.emerService.confirm(this.alertEMER).subscribe(msg => {
      this.close();
    }, (error) => { });

  }

}
