import { Component, Output, EventEmitter, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FaxService } from '../../services/fax.service'
import { AlertEMER, EVENTTYPE, RECIPIENT } from '../../../models/alertEMER'
import { Incident } from '../../../models/incident'



import { AuthService } from '../../../services/auth.service'
import { EmerService } from '../../services/emer.service'
import { PolygonEMER, Coord, CircleEMER } from '../../../models/area';
import OlVector from 'ol/source/vector';

@Component({
  selector: 'app-emer',
  templateUrl: './emer.component.html',
  styleUrls: ['./emer.component.scss']
})
export class EmerComponent implements OnInit {

  @Input() public vectorSource: OlVector;
  @Input() public alertEMER: AlertEMER;
  @Input() public incident: Incident;

  @Output() public refreshVectorSourceFromChild: EventEmitter<OlVector> = new EventEmitter<OlVector>();
  @Output() public addDrawInteractionFromChild: EventEmitter<String> = new EventEmitter<String>();
  @Output() public removeInteractionFromChild: EventEmitter<String> = new EventEmitter<String>();
  @Output() public clearMapFromChild: EventEmitter<String> = new EventEmitter<String>();
  @Output() public validateCoordinateFromChild: EventEmitter<String> = new EventEmitter<String>();
  public recipients: String[] = Object.keys(RECIPIENT);

  public eventTypes: String[] = Object.values(EVENTTYPE);
  options = [
    { name: 'Polygon', value: 'POLYGON' },
    { name: 'Circle', value: 'CIRCLE' },

  ]
  @ViewChild('otherEventType') otherEventType: ElementRef;
  @ViewChild('title') title: ElementRef;

  public selectedOption: String = 'Polygon';
  public coord1: String = null;
  public coord2: String = null;
  public _ref: any;
  public authorSource: String = '';
  public originalCreator: String = '';
  public pdfLinks = Array<string>();
  constructor(public faxService: FaxService, public authService: AuthService, public emerService: EmerService) { }

  ngOnInit() {
    // this.alertEMER.recipients = [RECIPIENT[this.alertEMER.alert.author]];
    this.clearMap();

    this.alertEMER.alert.addCustomPropertyOperator(this.authService.getOperator(this.alertEMER.alert.author));

      // the author is the recipient see emer1 eand emer2 component
    // allow to field directly the recipient at the and of the form
    this.authorSource = this.alertEMER.alert.author;
    this.originalCreator = this.authService.getOriginalCreator(this.incident, this.alertEMER);
    if (this.alertEMER.key === null) {
      this.alertEMER.recipients.push(RECIPIENT['SOR']);
    }

      //NOTE#1.1: Keep changed data (check the same in NOTE#1.2)
    if (this.alertEMER.alert.author === 'SOR') {
      this.alertEMER.recipients.push(RECIPIENT['SOR']);
      const newAuthor = this.alertEMER.recipients[0];
      this.alertEMER.recipients = [RECIPIENT[this.alertEMER.alert.author]];
      this.alertEMER.alert.author = newAuthor;
    }


    this.displayPolygon();
    if (!this.alertEMER.area.polygons && !this.alertEMER.area.circles) {
      this.addDrawInteractionChild();
    } else {
      this.validateCoordinate(this.vectorSource.getFeatures()[0]);
    }

    // if the type doesn't exist into the enum eventtype
    if (this.eventTypes.indexOf(this.alertEMER.alert.eventType) === -1 && this.alertEMER.alert.eventType !== '') {
      this.otherEventType.nativeElement.value = this.alertEMER.alert.eventType;
      this.alertEMER.alert.eventType = EVENTTYPE.Other;
    }
  }

  removeObject() { this._ref.destroy(); }
  validateCoordinate(feature) {

    // console.log('validateCoordinate');

    let featureConverted = feature;
    const geometryName = feature.getGeometry().getType();
    featureConverted = feature.getGeometry().transform('EPSG:3857', 'EPSG:4326');

    if (geometryName === 'Polygon') {
      const polygon = new PolygonEMER(new Array<Coord>(), true);
      const coord = this.emerService.getCenter(featureConverted.getCoordinates()[0])
      for (const featureCoord of featureConverted.getCoordinates()[0]) {
        polygon.coordinates.push(new Coord(featureCoord[0], featureCoord[1], 0));
      }

      this.coord1 = this.faxService.ddToDms(coord[1].toString(), coord[0].toString())[0];
      this.coord2 = this.faxService.ddToDms(coord[1].toString(), coord[0].toString())[1];

      this.alertEMER.area.polygons = new Array<PolygonEMER>();
      this.alertEMER.area.polygons.push(polygon);
    } else
      if (geometryName === 'Circle') {
        const circle = new CircleEMER(new Coord(0, 0, 0), 0);

        const coord = new Coord(featureConverted.getCenter()[0], featureConverted.getCenter()[1], 0);
        circle.center = coord;
        circle.radius = featureConverted.getRadius();
        // to display
        this.coord1 = this.faxService.ddToDms(featureConverted.getCenter()[1], featureConverted.getCenter()[0])[0];
        this.coord2 = this.faxService.ddToDms(featureConverted.getCenter()[1], featureConverted.getCenter()[0])[1];
        this.alertEMER.area.circles = new Array<CircleEMER>();

        this.alertEMER.area.circles.push(circle);
      }
    feature.getGeometry().transform('EPSG:4326', 'EPSG:3857');

  }
  addDrawInteractionChild() { this.addDrawInteractionFromChild.emit(this.selectedOption); }
  removeInteractionChild() { this.removeInteractionFromChild.emit(); }
  clearMap() {
    this.clearMapFromChild.emit();
  }

  displayPolygon() {
    if (this.incident) {
      // console.log('displayPolygon');
      this.vectorSource = this.emerService.alertToVectorSource(this.alertEMER, this.incident);
      this.refreshVectorSourceFromChild.emit(this.vectorSource); // emmiting the event.
      this.removeInteractionChild();
    }
  }

  // METHODES TO DISABLE FIELDS AND BUTTON
  globalEditable() {
    if (this.incident) {
      return (!this.incident.reCo);
    } else { return true };
  }

  notesDisabled(): Boolean {
      return (this.incident !== undefined && this.incident.status !== undefined);
  }

  geometrySelectDisabled() {

    return ((this.alertEMER.area.polygons && this.alertEMER.area.circles) || this.alertEMER.key !== null)
  }
  cityLocalityInputDisabled() {
    return (this.globalEditable() && (this.alertEMER.alert.eventType === ''
      || (!this.alertEMER.area.polygons && !this.alertEMER.area.circles)
      || !this.globalEditable()));
  }
  alertTitleInputDisabled() {
    return (this.globalEditable() && (this.alertEMER.alert.getcustomProperty('locality').value == null
      || this.alertEMER.alert.getcustomProperty('city').value == null))
      || !this.globalEditable() || (!this.alertEMER.area.polygons && !this.alertEMER.area.circles);
  }

  changeCityAndLocality() {
    const date = new Date();
    let locality;
    if (this.alertEMER.alert.getcustomProperty('locality').value) {
      locality = this.alertEMER.alert.getcustomProperty('locality').value;
    } else { locality = ''; }
    let city;
    if (this.alertEMER.alert.getcustomProperty('city').value) {
      city = this.alertEMER.alert.getcustomProperty('city').value;
    } else { city = ''; }

    this.alertEMER.alert.headline = city + '-' + locality + '-' +
      date.toISOString();

  }

  changeOther(eventType) {
      if(eventType == EVENTTYPE.Other) {
          this.alertEMER.alert.getcustomProperty('other').value = this.otherEventType.nativeElement.value;
      } else {
          this.alertEMER.alert.getcustomProperty('other').value = '';
      }
  }

  changeTitle(event) {
    this.alertEMER.alert.headline = this.title.nativeElement.value;
  }
  otherFieldDisabled() {
    return (this.alertEMER.key === null && (this.alertEMER.alert.headline === ''))
      || !this.globalEditable() || (!this.alertEMER.area.polygons && !this.alertEMER.area.circles);
  }
  // END OF METHODES TO DISABLE FIELDS AND BUTTON

}
