import { Component, OnInit } from '@angular/core';
import { AuthService } from './../services/auth.service'
import { TranslateService } from '@ngx-translate/core';
import { LocalizeParser } from 'localize-router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
  isCollapsed: Boolean = true;
  public profilePath = null;
  public homePath = null;
  public userName = '';
  constructor(private authService: AuthService, public translate: TranslateService, public localize: LocalizeParser) {
    let selectedLanguage = localize.getLocationLang();
    if (!selectedLanguage) { selectedLanguage = localize.defaultLang };

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(selectedLanguage);
    translate.use(selectedLanguage);
    this.homePath = environment.baseUrl + this.localize.translateRoute(selectedLanguage + '/mapview');
    this.profilePath = environment.baseUrl + this.localize.translateRoute(selectedLanguage + '/profile');

  }
  ngOnInit() {
    const user = this.authService.getUser();
    if (user) {
      this.userName = user.firstname + ' ' + user.lastname;
    }
  }
  logout() { this.userName = ''; this.authService.logout(); }
}
