import {Component, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
    projectName: string = environment.NAME;
    projectVersion: string = environment.VERSION;
    projectTheme: string = environment.theme;
    projectTitle: string = environment.title;
    projectDescription: string = environment.description;
    isProduction: boolean = environment.production;
    envName: string = environment.envName;

    ngOnInit() {
    }

    constructor(private authService: AuthService) {
    }

}
