// ANGULAR IMPORTS
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// CUSTOM COMPONENTS IMPORTS
import { LoginComponent } from './login/login.component';
import { MapViewComponent } from './mapView/mapView.component';
import { ProfileComponent } from './profile/profile.component';
import { AboutComponent } from './about/about.component';

// CUSTOM SERVICES IMPORTS
import { AuthGuard } from './services/authGuard';


export const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent, canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'about',
    component: AboutComponent, canActivate: [AuthGuard]
  },

  { path: 'mapview', component: MapViewComponent, canActivate: [AuthGuard] },
  { path: '', component: LoginComponent },
  { path: '**', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})

export class AppRoutingModule {

}
