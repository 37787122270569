import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service'

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {
  public profile = null;
  public profileKeys: string[];
  constructor(private authService: AuthService) {
  }

  ngOnInit() {
    this.profile = this.authService.getUser();
    this.profileKeys = Object.keys(this.profile);
  }
}
