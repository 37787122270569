import { Area, PolygonEMER, CircleEMER } from './area';
import { Reference } from './alertEMER';

export const GLOBAL_INCIDENT_STATUS = [
    { key: 'CREATED', value: 'New' },
    { key: 'ACKNOWLEGED', value: 'Ack.' },
    { key: 'RESOURCES_REQUESTED', value: 'Req.' },
    { key: 'RESOURCES_OFFERED', value: 'Offered' },
    { key: 'CONFIRMED', value: 'Conf.' }
]

export const INCIDENT_STATUS = [
    { key: 'CREATED', value: 'CREATED' },
    { key: 'SENT', value: 'SENT' },
    { key: 'VALIDATED_BY_CORS', value: 'VALIDATED_BY_CORS' },
    { key: 'ACKNOWLEGED_BY_CORS', value: 'ACKNOWLEGED_BY_CORS' },
    { key: 'ACKNOWLEGED_BY_RECO', value: 'ACKNOWLEGED_BY_RECO' },
    { key: 'ACKNOWLEGED_BY_SOR', value: 'ACKNOWLEGED_BY_SOR' },
    { key: 'UPDATED', value: 'UPDATED' },
    { key: 'RESOURCES_REQUESTED', value: 'RESOURCES_REQUESTED' },
    { key: 'RESOURCES_OFFERED', value: 'RESOURCES_OFFERED' },
    { key: 'CLOSED', value: 'CLOSED' },
    { key: 'CONFIRMED_BY_CORS', value: 'CONFIRMED_BY_CORS' },
    { key: 'CONFIRMED_BY_SOR', value: 'CONFIRMED_BY_SOR' }
];


export enum MESSAGE_STATUS {
    EVENT_TYPE_CIS_INFORM = 'EVENT_TYPE_CIS_INFORM',
    EVENT_TYPE_CIS_REQUEST = 'EVENT_TYPE_CIS_REQUEST',
    EVENT_TYPE_CIS_OFFER = 'EVENT_TYPE_CIS_OFFER',
    EVENT_TYPE_CIS_ACK = 'EVENT_TYPE_CIS_ACK',
}

interface IMessageReferences {
    identifier?: string;
    sender?: String;
    type?: String;
    timestamp?: String;
    status?: MESSAGE_STATUS;
}
// see https://stackoverflow.com/questions/12702548/constructor-overload-in-typescript
export class MessageReferences {
    public identifier?: string = null;
    public sender?: String = '';
    public type?: String = '';
    public timestamp?: String = '';
    public status?: MESSAGE_STATUS;
    constructor(obj: IMessageReferences);
    constructor(obj?: any) {
        if (obj && obj.identifier) {
            this.identifier = obj.identifier
        }
        if (obj && obj.sender) {
            this.sender = obj.sender
        }
        if (obj && obj.type) {
            this.type = obj.type
        }
        if (obj && obj.timestamp) {
            this.timestamp = obj.timestamp
        }
        if (obj && obj.status) {
            this.status = obj.status
        }
    }
}


interface IIncident {
    id?: string;
    incidentId?: string;
    incidentHeadline?: string;
    creationTime?: string;
    lastUpdateTime?: string;
    messageReferences?: MessageReferences[];
    source?: string;
    status?: string;
    reCo?: string;
    area?: Area;
}
// see https://stackoverflow.com/questions/12702548/constructor-overload-in-typescript
export class Incident {
    public id?: string = null;
    public incidentId?: string = null;
    public incidentHeadline?: string = null;
    public creationTime?: string = null;
    public lastUpdateTime?: string = null;
    public messageReferences?: MessageReferences[] = new Array<MessageReferences>();
    public source?: string = null;
    public status?: string;
    public reCo?: string;
    public area?: Area = new Area({
        polygons: new Array<PolygonEMER>(),
        circles: new Array<CircleEMER>()
    });
    constructor(obj: IIncident);
    constructor(obj?: any) {
        if (obj && obj.id) {
            this.id = obj.id
        }
        if (obj && obj.incidentId) {
            this.incidentId = obj.incidentId
        }
        if (obj && obj.incidentHeadline) {
            this.incidentHeadline = obj.incidentHeadline
        }
        if (obj && obj.creationTime) {
            this.creationTime = obj.creationTime
        }
        if (obj && obj.lastUpdateTime) {
            this.lastUpdateTime = obj.lastUpdateTime
        }
        if (obj && obj.messageReferences) {
            this.messageReferences = obj.messageReferences
        }
        if (obj && obj.source) {
            this.source = obj.source
        }
        if (obj && obj.status) {
            this.status = obj.status
        }
        if (obj && obj.reCo) {
            this.reCo = obj.reCo
        }

        if (obj && obj.area) {
            this.area = new Area(obj.area);
        }
    }


    public getMessageReferencesByStatus(status: MESSAGE_STATUS) {
        if (!this.messageReferences.find(obj => obj.status === status)) {
            return new MessageReferences({
                identifier: '',
                sender: '',
                type: '',
                timestamp: '',
                status: null
            })
        } else {
            return this.messageReferences.find(obj => obj.status === status);
        }
    }

    public getLastReference(): MessageReferences {
        let lastReference: MessageReferences = null;
        this.messageReferences.forEach(function (ref) {
            // console.log('search lastReference');
            // console.log(ref);
            if (lastReference === null
                || (lastReference !== null && ref.status.indexOf('ACK') === -1 &&
                    new Date(lastReference.timestamp.toString()) <= new Date(ref.timestamp.toString()))) {
                lastReference = ref;

            }
        })
        // console.log('lastReference');
        // console.log(lastReference);
        return lastReference;
    }

    public getLatCenter() {
        return this.area.polygons[0].coordinates[0].lng.toString();
    }

    public getLngCenter() {
        return this.area.polygons[0].coordinates[0].lat.toString();
    }

}
