import { Component } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service'
import { AuthRes } from '../models/authRes';
import { LocalizeRouterService } from 'localize-router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  public form: FormGroup;
  public username: AbstractControl;
  public password: AbstractControl;
  public submitted: Boolean = false;

  public error: Boolean = false;
  public errorMessage: String = '';

  public ableSubmitted: Boolean = true;

  constructor(fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private localize: LocalizeRouterService,
    private activatedRoute: ActivatedRoute) {
    this.form = fb.group({
      'username': ['', Validators.compose([Validators.required, Validators.minLength(4)])],
      'password': ['', Validators.compose([Validators.required, Validators.minLength(4)])],
    });
    this.username = this.form.controls['username'];
    this.password = this.form.controls['password'];
    this.activatedRoute.queryParams.subscribe(params => {
      const token = params['token'];
      // console.log(token);
      if (token) {
        this.authService.loginWithToken(token);
        const translatedPath: any = this.localize.translateRoute('/mapview');
        // console.log('Redirect to ' + translatedPath);
        this.router.navigate([translatedPath]);
      }
    });
  }

  public onSubmit(values: Object): void {
    this.submitted = true;
    this.ableSubmitted = false;
    if (this.form.valid) {
      this.authService.loginAndAuthorizeUser(values).subscribe(() => {
        // console.log(data);
        if (this.authService.getAccessToken() !== null) {
          this.error = false;
          this.authService.getUserDetails(this.authService.getAccessToken()).subscribe(() => {
            const translatedPath: any = this.localize.translateRoute('/mapview');
            // console.log('Redirect to ' + translatedPath);
            this.router.navigate([translatedPath]);
          });

        }
      },
        err => {
          this.error = true;
          this.errorMessage = err.error.error_description;
          console.log(err.error);
        },
      );
    }
  }
  onChange() {
    this.ableSubmitted = true;
  }
}
