import { Component, Output, EventEmitter, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { AlertEMER } from '../../../models/alertEMER'
import { Incident, GLOBAL_INCIDENT_STATUS, INCIDENT_STATUS, MESSAGE_STATUS } from '../../../models/incident'
import { EmerService } from '../../services/emer.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { environment } from '../../../../environments/environment';
import OlLayerVector from 'ol/layer/vector';
import OlVector from 'ol/source/vector';
import * as deepEqual from 'deep-equal';
import { ModalDirective } from 'ngx-bootstrap/modal';
import {FaxService} from '../../services/fax.service';


@Component({
  selector: 'app-emerlist',
  templateUrl: './emerList.component.html',
  styleUrls: ['./emerList.component.scss']
})


export class EmerListComponent {
  public _ref: any;
  // public incidents: Incident[] = new Array<Incident>();
  private currentTimeOut = null;
  // List of incident order by status. key = status ; value = list of incidents with its alerts
  public incidentsByStatus: Map<String, [{ incident: Incident, alert: AlertEMER[] }]>;
  // list of incidents displayed. key = incidentId ; value = incident + alert
  public currentIncidents: Map<string, { incident: Incident, alert: AlertEMER[] }>;
  public incidentStatus = INCIDENT_STATUS;
  public globalIncidentStatus = GLOBAL_INCIDENT_STATUS;
  public sort: Boolean = false;
  public sortWay: Boolean = false;
  public sorting: Boolean = false;
  public changeFoundNeedSort: Boolean = false;
  public message_status = MESSAGE_STATUS;
  public incidentToRemove = null;
  private canBeRefresh;
  public incidentsUpdate = { 'ADD': new Array<Incident>(), 'UPDATE': new Array<Incident>(), 'DELETE': new Array<Incident>() };
  messages: string[];

  modalRef: BsModalRef;
  message: string;

  public isFaxError: Boolean = false;

  @Output() public openExplorerFromChild: EventEmitter<object> = new EventEmitter<object>();
  @Output() public refreshVectorSourceFromChild: EventEmitter<OlVector> = new EventEmitter<OlVector>();
  @Output() public clearMapFromChild: EventEmitter<String> = new EventEmitter<String>();
  @Output() public addDrawInteractionFromChild: EventEmitter<String> = new EventEmitter<String>();
  @Output() public removeInteractionFromChild: EventEmitter<String> = new EventEmitter<String>();
  @Output() public fitMapFromCurrentVectorSourceFromChild: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('template') template: TemplateRef<any>;
  @ViewChild('templateShowMessage') templateShowMessage: TemplateRef<any>;
  @ViewChild('alertEventModale') alertEventModale: TemplateRef<any>;

  @ViewChild('alertsTable') alertsTable: ElementRef;


  removeObject() {
    // console.log('removeObject emerlist')
    clearInterval(this.currentTimeOut);
    // this.currentIncidents = new Map<string, { incident: Incident, alert: AlertEMER[] }>();
    // console.log(this.currentIncidents.keys());
    // const vectorSource = new OlVector();
    // this.refreshVectorSourceFromChild.emit(vectorSource);
    this._ref.destroy();
  }

  constructor(public emerService: EmerService, private modalService: BsModalService, private faxService: FaxService) {

    this.openExplorerFromChild.emit({ view: 'explorerView', incident: null, alert: null })
    this.incidentsByStatus = new Map<String, [{ incident: Incident, alert: AlertEMER[] }]>();
    this.currentIncidents = new Map<string, { incident: Incident, alert: AlertEMER[] }>();

    this.canBeRefresh = false;
    this.emerService.getIncidents().subscribe((incidents: Incident[]) => {
      const layerVector = new OlLayerVector();
      // create map to display incidents by status
      const vectorSource = new OlVector();
      for (let incident of incidents) {
        incident = new Incident(incident);
        // if no object for this status then it is created
        if (!this.incidentsByStatus.get(incident.status)) {
          this.incidentsByStatus.set(incident.status, [{ incident: incident, alert: new Array<AlertEMER>() }]);
        }
        // if no object for this status then it is created

        if (!this.currentIncidents.get(incident.incidentId)) {
          this.currentIncidents.set(incident.incidentId, { incident: incident, alert: new Array<AlertEMER>() });
        }
        // console.log('get alerts for incident:' + incident.incidentId);
        this.emerService.getAlertsByIncident(incident.incidentId).subscribe(alertsEmer => {
          // console.log(alertsEmer);

          // we get the last alert sent


          const lastAlert = alertsEmer.find(obj => obj.key && obj.key === incident.getLastReference().identifier);

          this.currentIncidents.get(incident.incidentId).alert.push(lastAlert);
          const vector = this.emerService.alertToVectorSource(lastAlert, incident);
          if (!this.incidentsByStatus.get(incident.status).find(obj => obj.incident.incidentId === incident.incidentId)) {
            this.incidentsByStatus.get(incident.status).push({ incident: incident, alert: [lastAlert] });
            this.changeFoundNeedSort = true;
          }
          for (const feature of vector.getFeatures()) {
            vectorSource.addFeature(feature);
          }
          this.fitMapFromCurrentVectorSourceFromChild.emit();
          this.canBeRefresh = true;

        });
      }
      this.refreshVectorSourceFromChild.emit(vectorSource);
      const that = this;
      this.currentTimeOut = setInterval(function () { that.getAlerts() }, environment.timeout);

    });

  }
  openModalDelete(incidentId) {
    this.incidentToRemove = incidentId;
    this.modalRef = this.modalService.show(this.template);
  }

  handler(type: string, $event: ModalDirective) {
    console.log(type);
    this.incidentsUpdate = { 'ADD': new Array<Incident>(), 'UPDATE': new Array<Incident>(), 'DELETE': new Array<Incident>() };

  }

  openModalAlertEvent() {
    this.decline();
    if (this.incidentToRemove === null) {
      this.modalRef = this.modalService.show(this.alertEventModale);
      const audio = new Audio('assets/audio/alarm.wav');
      audio.play();
    }
    this.modalService.onHide.subscribe(
      result => {
        if (result === 'backdrop-click') {
          this.incidentsUpdate = { 'ADD': new Array<Incident>(), 'UPDATE': new Array<Incident>(), 'DELETE': new Array<Incident>() };
        }
      }, error => console.log(error));
  }


  getElapsedTime(lastUpdateTime) {
    const secs = Math.round((new Date().getTime() - Date.parse(lastUpdateTime)) / 1000);
    const days = Math.floor(secs / (24 * 60 * 60));
    /*let stringDays = Math.floor(secs / (24 * 60 * 60)).toString();
    (days > 0) ? stringDays = days + 'd ' : stringDays = '';*/
    const hours = Math.floor((secs - (days * 24 * 60 * 60)) / (60 * 60));
    const divisor_for_minutes = secs % (60 * 60);
    const minutes = Math.floor(divisor_for_minutes / 60);
    const divisor_for_seconds = divisor_for_minutes % 60;
    const seconds = Math.ceil(divisor_for_seconds);
    //EFL format date
    if( days > 1){
      return days + " days ago";
    }else if(days == 1){
      return days + " day ago";
    }else if(days == 0){
      return hours + ':' + (minutes > 9 ? '' : '0') + minutes + ':' + (seconds > 9 ? '' : '0') + seconds;
    }
    /*return stringDays + (hours > 9 ? '' : '0') + hours
            + ':' + (minutes > 9 ? '' : '0') + minutes
            + ':' + (seconds > 9 ? '' : '0') + seconds;*/
  }

  openExplorer(incident: Incident) {
    console.log('openExplorer. Init...');
    clearInterval(this.currentTimeOut);
    incident = new Incident(incident);

    this.clearMapFromChild.emit();
    incident = new Incident(incident);
    let view = null;
    let messageReference = null;

    (incident.status !== 'RESOURCES_REQUESTED' && incident.status !== 'RESOURCES_OFFERED') ? view = 'emer1View' : view = 'emer2View';
    (incident.status !== 'RESOURCES_REQUESTED' && incident.status !== 'RESOURCES_OFFERED') ?
      messageReference = incident.getMessageReferencesByStatus(this.message_status.EVENT_TYPE_CIS_INFORM).identifier :
      messageReference = incident.getMessageReferencesByStatus(this.message_status.EVENT_TYPE_CIS_REQUEST).identifier;
    if (incident) {
      this.emerService.getAlertsByIncident(incident.incidentId).subscribe(alertsEmer => {
        console.log('openExplorer. Done.');
        // we get the last alert sent
        const lastAlert = alertsEmer.find(obj => obj.key && obj.key === incident.getLastReference().identifier);
        const alertEMER: AlertEMER = new AlertEMER(lastAlert);
        // console.log(alertsEmer);

        this.openExplorerFromChild.emit({ view: view, incident: incident, alert: alertEMER })
      }, (error) => {
          console.log('openExplorer. ERROR: ' + error);
      });
    } else {
        console.log('openExplorer. Done (no incident).');
      this.openExplorerFromChild.emit({ view: view, incident: incident, alert: null })
    }
  }


  seeAlert(incident: Incident, alert: AlertEMER[]) {
    this.incidentsByStatus = new Map<String, [{ incident: Incident, alert: AlertEMER[] }]>();
    // clearInterval(this.currentTimeOut);

    if (this.currentIncidents.get(incident.incidentId)) {
      this.currentIncidents.delete(incident.incidentId);
      // console.log('exist' + this.currentIncidents.size);
    } else {
      this.currentIncidents.set(incident.incidentId, { incident: incident, alert: alert });
      // console.log('not exist' + this.currentIncidents.size);
    }

    this.currentIncidents.forEach(({ incident: currentIncident, alert: currentAlert }, currentIdIncident: string) => {
      const alerEMER: AlertEMER = new AlertEMER(alert[0]);
      // create map to display incidents by status
      const vectorSource = new OlVector();

      const vector = this.emerService.alertToVectorSource(alert[0], currentIncident);
      // console.log('FEATURE UNIQUE');
      // console.log(vector.getFeatures());
      for (const feature of vector.getFeatures()) {
        vectorSource.addFeature(feature);

        // console.log(feature);
      }
      // console.log('EXTEND');
      // console.log(vectorSource.getExtent());
      this.refreshVectorSourceFromChild.emit(vectorSource);

    })
  }

  getAlerts() {
    // console.log('getAlerts:');
    // console.log(this.canBeRefresh);

    if (this.canBeRefresh) {
      this.canBeRefresh = false;
      this.emerService.getIncidents().subscribe((incidents: Incident[]) => {

        const layerVector = new OlLayerVector();
        // create map to display incidents by status
        const vectorSource = new OlVector();
        let refreshMap = false;

        const incidentsToRemove = new Map(this.currentIncidents);

        // *** find and remove incidents ***
        // find removed incidents
        for (let incident of incidents) {
          incident = new Incident(incident);

          // if exist then we don't want to keep it into the incidentsToRemove
          if (this.currentIncidents.get(incident.incidentId)
            && deepEqual(this.currentIncidents.get(incident.incidentId).incident, incident)) {
            // it will be not removed
            incidentsToRemove.delete(incident.incidentId);
          } else if (!this.currentIncidents.get(incident.incidentId)) {
            this.incidentsUpdate.ADD.push(incident);

            // it will be removed
          }
        }

        // remove the incidents from currentIncidents
        const it = incidentsToRemove.values();
        let next = it.next();
        while (next.value) {
          const incidentToremove = next.value.incident;
          // if only status is changed then it is an update
          if (this.currentIncidents.get(incidentToremove.incidentId)
            && this.currentIncidents.get(incidentToremove.incidentId).incident.status !== incidentToremove.status) {
            this.incidentsUpdate.UPDATE.push(incidentToremove);
          } else {
            if (this.incidentToRemove === incidentToremove) {
              this.incidentToRemove = null;
            }
            this.incidentsUpdate.DELETE.push(incidentToremove);
          }
          this.currentIncidents.delete(next.value.incident.incidentId);
          next = it.next();
          refreshMap = true;
        }
        // clear incidentsByStatus if incidents have been removed
        if (incidentsToRemove.size > 0) {
          refreshMap = true;
          this.incidentsByStatus = new Map<String, [{ incident: Incident, alert: AlertEMER[] }]>();
        }

        for (let incident of incidents) {
          incident = new Incident(incident);

          // if the incident is not into currentIncidents
          if (!this.currentIncidents.get(incident.incidentId)) {
            this.currentIncidents.set(incident.incidentId, { incident: incident, alert: new Array<AlertEMER>() });
            refreshMap = true;


            if (this.incidentsUpdate.DELETE.findIndex(incidentUpdate => incident.incidentId === incidentUpdate.incidentId) > -1) {
              this.incidentsUpdate.UPDATE.push(incident);
              console.log(this.incidentsUpdate.DELETE.splice(
                this.incidentsUpdate.DELETE.findIndex(incidentTmp => incidentTmp.incidentId === incident.incidentId)));
            }
          }

          // if no object for this status then it is created
          if (!this.incidentsByStatus.get(incident.status)) {
            this.incidentsByStatus.set(incident.status, [{ incident: incident, alert: new Array<AlertEMER>() }]);
          }

          // if no object for this status then it is created
          this.emerService.getAlertsByIncident(incident.incidentId)
            .subscribe(alertsEmer => {
              // we get the last alert sent
              const lastAlert = alertsEmer.find(obj => obj.key === incident.getLastReference().identifier);

              const vector = this.emerService.alertToVectorSource(lastAlert, incident);

              // if the incident is not into incidentBystatus Array
              if (!this.incidentsByStatus.get(incident.status).find(obj => obj.incident.incidentId === incident.incidentId)) {
                this.incidentsByStatus.get(incident.status).push({ incident: incident, alert: [lastAlert] });
                this.changeFoundNeedSort = true;
              }
              // if the incident is in currentincident then it is displayed
              if (this.currentIncidents.get(incident.incidentId)) {
                // console.log('FEATURE');
                for (const feature of vector.getFeatures()) {
                  vectorSource.addFeature(feature);
                }
              }

            });
        }
        this.canBeRefresh = true;
        // refresh only if the incident array is changed
        if (refreshMap) {
          this.refreshVectorSourceFromChild.emit(vectorSource);

          // if there was an incident to remove then we don't display the aler modale and we reset the variable

          this.openModalAlertEvent();

        }
        this.display();
      });

    }
  }

  display() {
    if (this.incidentsByStatus) {
      this.sortTable();
      return 'block';
    } else {
      return 'none';
    }
  }
  sortTable() {
    try {
      if(this.sorting) {
        return;
      } else {
        if(!this.changeFoundNeedSort || this.alertsTable == undefined || this.alertsTable.nativeElement == undefined) {
          return;
        }
        this.sorting = true;
      };

      let sortColumnIndex = 0;  //by default by time update (incidentAlerts.incident.lastUpdateTime)
      if(this.sort) {
          sortColumnIndex = 1;  //by status (incidentAlerts.incident.status)
      };

      let table, rows, switching, i, x, y, shouldSwitch;
      table = this.alertsTable.nativeElement;
      // console.log(table);
      switching = true;
      /* Make a loop that will continue until
      no switching has been done: */
      while (switching) {
        // Start by saying: no switching is done:
        switching = false;
        rows = table.rows;
        /* Loop through all table rows (except the
        first, which contains table headers): */
        for (i = 1; i < (rows.length - 1); i++) {
          // Start by saying there should be no switching:
          shouldSwitch = false;
          /* Get the two elements you want to compare,
          one from current row and one from the next: */
          x = rows[i].getElementsByTagName('td')[sortColumnIndex];
          y = rows[i + 1].getElementsByTagName('td')[sortColumnIndex];
          // Check if the two rows should switch place:
          if(!this.sortWay) {
              if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
                  // If so, mark as a switch and break the loop:
                  shouldSwitch = true;
                  break;
              }
          } else {
              if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                  // If so, mark as a switch and break the loop:
                  shouldSwitch = true;
                  break;
              }
          }
        }
        if (shouldSwitch) {
          /* If a switch has been marked, make the switch
          and mark that a switch has been done: */
          rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
          switching = true;
        }
      }
    } catch (ex) {
      console.error(ex);
    };
    this.sorting = false;
    this.changeFoundNeedSort = false;
  }

  getStatusWeight(status: String) {
    //Incident status
    if(status == 'CREATED') {
        return 99;
    }
    else if(status == 'SENT') {
        return 98;
    }
    else if(status == 'VALIDATED_BY_CORS') {
        return 97;
    }
    ////
    else if(status == 'ACKNOWLEGED') {
        return 88;
    }
    else if(status == 'ACKNOWLEGED_BY_CORS') {
        return 87;
    }
    else if(status == 'ACKNOWLEGED_BY_RECO') {
        return 86;
    }
    else if(status == 'ACKNOWLEGED_BY_SOR') {
        return 85;
    }
    ////
    else if(status == 'UPDATED') {
        return 79;
    }
    ////
    else if(status == 'RESOURCES_REQUESTED') {
        return 69;
    }
    ////
    else if(status == 'RESOURCES_OFFERED') {
        return 59;
    }
    ////
    else if(status == 'CONFIRMED_BY_CORS') {
        return 49;
    }
    ////
    else if(status == 'CONFIRMED_BY_SOR') {
        return 48;
    }
    ////
    else if(status == 'CLOSED') {
        return 39;
    }

    else {
         console.error(status);
        return 0;
    }
  }

  getIncidentByStatus(status: string): Array<any> {
    if (this.incidentsByStatus.get(status)) {
      // console.log('getIncidentByStatus');
      // console.log(this.incidentsByStatus.get(status));
      return this.incidentsByStatus.get(status);
    } else { return [] };
  }

  decline(): void {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  deleteIncident() {
    this.emerService.deleteIncident(this.incidentToRemove).subscribe(res => {
      console.log(res);
      this.changeFoundNeedSort = true;
      this.getAlerts();
      this.decline();
    });

  }

  createFaxIncident(incidentId: string) {
    this.isFaxError = false;
    this.modalRef = this.modalService.show(this.templateShowMessage);

    this.faxService.createFaxIncident(incidentId).subscribe(res => {
      this.faxService.displayAndSavePDF(res);
      this.modalRef.hide();

    }, (error) => {
      console.error(error);

      this.isFaxError = true;

      setTimeout(() =>
          this.modalRef.hide()
      , 3000);
    });
  }


}
