import { Component, Output, EventEmitter, Input, TemplateRef, ViewChild, OnInit } from '@angular/core';
import { FaxService } from '../../services/fax.service';
import { AlertEMER, RECIPIENT, Reference } from '../../../models/alertEMER';
import { Alert, SEVERITY, CERTAINTY, URGENCY, CATEGORY_BY_EVENTTYPE, } from '../../../models/alert';
import { Incident } from '../../../models/incident';
import { EmerService } from '../../services/emer.service';
import OlVector from 'ol/source/vector';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../services/auth.service';
import { EmerComponent } from '../emer/emer.component'
@Component({
  selector: 'app-emer1',
  templateUrl: './emer1.component.html',
  styleUrls: ['./emer1.component.scss']
})
export class Emer1Component implements OnInit {
  public _ref: any;
  modalRef: BsModalRef;
  @ViewChild('emer') emer: EmerComponent;

  public recipients: String[] = Object.keys(RECIPIENT);
  public recipientsObj = RECIPIENT;
  public isReco = true;
  public isCreation = false;
  public isAck: Boolean = false;
  public canCreateEmer2: Boolean = false;
  public recos = [];
  private categoryByEventType = CATEGORY_BY_EVENTTYPE;

  @ViewChild('template') template: TemplateRef<any>;
  @Input() public vectorSource: OlVector;
  @Input() public alertEMER: AlertEMER;
  @Input() public incident: Incident;

  @Output() public refreshVectorSourceFromChild: EventEmitter<OlVector> = new EventEmitter<OlVector>();
  @Output() public addDrawInteractionFromChild: EventEmitter<String> = new EventEmitter<String>();
  @Output() public removeInteractionFromChild: EventEmitter<String> = new EventEmitter<String>();
  @Output() public clearMapFromChild: EventEmitter<String> = new EventEmitter<String>();
  @Output() public openExplorerFromChild: EventEmitter<object> = new EventEmitter<object>();
  @Output() public fitMapFromCurrentVectorSourceFromChild: EventEmitter<any> = new EventEmitter<any>();
  removeObject() { this._ref.destroy(); }

  ngOnInit() {
    this.alertEMER.alert.addCustomPropertyOperator(this.authService.getOperator(this.alertEMER.alert.author));

    this.recos = environment.RECOs;
    // create
    // console.log(this.incident);
    if ( this.incident && this.incident.reCo !== 'UNASSIGNED') {
      this.alertEMER.recipients.push(RECIPIENT[this.incident.reCo])
    };
    if (this.alertEMER.key === null) {
      this.isCreation = true;
      this.alertEMER.alert.author = this.authService.getUser().organizations[0];
    } else if (this.incident.status === 'CREATED') {
      // acknowledge
      this.isAck = this.alertEMER.alert.author !== this.authService.getUser().organizations[0] &&
        this.alertEMER.alert.getcustomProperty('ReCO').value !== this.authService.getUser().organizations[0];
    } else {
      // generate emer 2 from emer 1
      this.canCreateEmer2 = this.incident.status !== 'ACKNOWLEGED_BY_CORS' && this.incident.status !== 'CONFIRMED_BY_CORS' && this.incident.status !== 'CONFIRMED_BY_SOR';
      this.alertEMER.recipients = [RECIPIENT[this.alertEMER.alert.author]];
    }
    this.isReco = this.emerService.isReco();

  }
  constructor(public faxService: FaxService,
    public emerService: EmerService,
    private modalService: BsModalService,
    public authService: AuthService) {
    // initialize alertEMER attributes

    const alert = new Alert({

      author: '',
      category: this.categoryByEventType.Other,
      certainty: CERTAINTY.UNKNOWN_CERTAINTY,
      customProperties: [
        { key: 'city', value: '' },
        { key: 'locality', value: '' },
        { key: 'other', value: '' },
        { key: 'operator', value: '' }
      ],
      description: '',
      eventType: '',
      headline: '',
      severity: SEVERITY.UNKNOWN_SEVERITY,
      status: 'BROKEN',
      urgency: URGENCY.UNKNOWN_URGENCY,
    });

    this.alertEMER = new AlertEMER({
      alert: alert,
      reply: '',
      area: {

      },
      key: null,
      recipients: new Array<RECIPIENT>(),
    });

  }

  validateCoordinate(feature) { this.emer.validateCoordinate(feature); }

  isSelectedRecipient(topic) {
    return this.alertEMER.recipients.indexOf(topic) >= 0;
  }

  // METHODES TO DISABLE FIELDS AND BUTTON
  globalEditable() {
    if (this.alertEMER.key === null) {
      for (const userOrga of this.authService.getUser().organizations) {
        if (this.alertEMER.recipients.indexOf(this.recipients['userOrga'])) {
          return true;
        }
      }
    }
    return false;
  }

  sendButtonDisabled() {
    return !(this.alertEMER.alert.headline !== '' &&
      ((this.isReco && this.alertEMER.recipients.length === 1) || (!this.isReco && this.alertEMER.recipients.length >= 2)))
  }


  openModal() { this.modalRef = this.modalService.show(this.template); }
  closeModal() { this.modalRef.hide(); }

  previewFax() {
    /*const faxObject = this.faxService.createFaxEMER1Object(this.alertEMER);
    if (faxObject !== null) {
      this.openModal();
      this.faxService.preview(faxObject).subscribe(msg => {
        this.closeModal();
        this.faxService.displayAndSavePDF(msg);
      }, (error) => {
      }
      );
    }*/
  }

  acknowledgeAlert() {
    this.alertEMER.reference = {
      msgRefence: this.alertEMER.reply,
      incident: this.incident.incidentId
    };
    console.debug("acknowledge alert: "+JSON.stringify(this.alertEMER));
    this.emerService.acknowledge(this.alertEMER).subscribe(msg => {
      console.debug("alert is acknowledged: "+JSON.stringify(msg));
      this.close();
    }, (error) => { console.error(JSON.stringify(error)); });
  }

  sendAlert() {
    Object.keys(this.categoryByEventType).indexOf(this.alertEMER.alert.eventType) > -1 ?
      this.alertEMER.alert.category = this.categoryByEventType[this.alertEMER.alert.eventType] :
      this.alertEMER.alert.category = this.categoryByEventType.Other;


    this.alertEMER.area.areaDescription = this.alertEMER.alert.getcustomProperty('city').value;
    const faxObject = this.faxService.createFaxEMER1Object(this.alertEMER);
    if (faxObject !== null) {
      this.openModal();
      this.emerService.inform(this.alertEMER).subscribe(msg => {
        this.closeModal();
        this.close();
        if (this.alertEMER != null) {
          this.faxService.sendAlertEmer(faxObject).subscribe(success => {
            this.faxService.displayAndSavePDF(success);
            // this.openExplorerFromChild.emit({ view: 'explorerView', incident: null, alert: null });

          });
        }
      }, (error) => { this.closeModal() });
    }
  }

  close() {
    this.removeInteractionChild();
    this.clearMap()
    this.openExplorerFromChild.emit({ view: 'explorerView', incident: null, alert: null });
  }
  // END OF METHODES FOR BUTTONS
  openEMER2() {
    this.alertEMER.key = null;
    const newAuthor = this.alertEMER.recipients[0];
    this.alertEMER.recipients = [RECIPIENT[this.alertEMER.alert.author]];
    this.alertEMER.alert.author = newAuthor;
    this.openExplorerFromChild.emit({ view: 'emer2View', incident: this.incident, alert: this.alertEMER });
  }
  onChangeRecipient(recipient: string) {
    this.alertEMER.recipients = this.alertEMER.recipients.filter(function (element) {
      return element !== undefined;
    });
    const index = this.alertEMER.recipients.indexOf(RECIPIENT[recipient]);
    (index === -1) ? this.alertEMER.recipients.push(RECIPIENT[recipient]) : this.alertEMER.recipients.splice(index, 1);
  }

  addDrawInteractionChild(interactionName) { this.addDrawInteractionFromChild.emit(interactionName); }
  removeInteractionChild() {
    // console.log('removeInteractionChild');
    this.removeInteractionFromChild.emit();
  }
  clearMap() { this.clearMapFromChild.emit(); }
  refreshVectorSourceChild(vectorSource) {
    this.refreshVectorSourceFromChild.emit(vectorSource);
    this.fitMapFromCurrentVectorSourceFromChild.emit();
  }
}
