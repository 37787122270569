export class FaxEMER1 {
    constructor(
        public isItaly: String,
        public idDoc: String,
        public city: String,
        public comuneNum: String,
        public eNote: String,
        public locality: String,
        public isInfo: String,
        public isRequest: String,
        public isDrill: String,
        public isLandslide: String,
        public isFlood: String,
        public isEarthquake: String,
        public isFire: String,
        public isOther: String,
        public fromOperatorName: String,
        public from: String,
        public to: String,
        public toDate: String,
        public geoNorth: String,
        public geoSouth: String,
        public fromDate: String,
        public isOtherComment: String,
        public mi: String,
        public toOperatorName: String,
        public isPOSTOJNA: String,
        public isNOVAGORICA: String,
        public isKOPER: String,
        public isKRANJ: String,
        public toNote: String,
        public descriptionInfo: String,
        public descriptionRequest: String,
        public descriptionDrill: String,
    ) { }
}

export class FaxEMER2 {
    public request: String = '';
    constructor(
        public isItaly: String,
        public idDoc: String,
        public city: String,
        public comuneNum: String,
        public locality: String,
        public isPROSNJA: String,
        public isVAJA: String,
        public isLandslide: String,
        public isFlood: String,
        public isEarthquake: String,
        public isOther: String,
        public geoNorth: String,
        public geoSouth: String,
        public mi: String,
        public toOperatorName: String,
        public toOperatorNameRequest: String,
        public toOperatorNameOffer: String,
        public isPOSTOJNA: String,
        public isNOVAGORICA: String,
        public isKOPER: String,
        public isKRANJ: String,
        public toNote: String,
        public dateDay: String,
        public hourDay: String,
        public personnelRequest: String,
        public personnelOffer: String,
        public vehiclesRequest: String,
        public vehiculesOffer: String,
        public foodRequest: String,
        public foodOffer: String,
        public tentsRequest: String,
        public tentsOffer: String,
        public heliRequest: String,
        public heliOffer: String,
        public localisation: String,
        public officerRequest: String,
        public officerOffer: String,
        public interpreterRequest: String,
        public interpreterOffer: String,
        public notesRequest: String,
        public notesOffer: String,
        public dateOperation: String,
        public hourOperation: String,
        public dateOperator: String,
        public hourOperator: String,
        public message: String,
        public meetingpointRequest: String,
        public meetingpointOffer: String,
        public operatorRequest: String,
        public operatorOffer: String,
        public radiochannelRequest: String,
        public radiochannelOffer: String,

    ) { }
}






