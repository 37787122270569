import { Component, Input, Output, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AlertEMER } from '../../../models/alertEMER';

@Component({
  selector: 'app-emerbuttons',
  templateUrl: './emerButtons.component.html',
  styleUrls: ['./emerButtons.component.scss']
})
export class EmerButtonsComponent {

  modalRef: BsModalRef;

  @ViewChild('templateConfirm') templateConfirm: TemplateRef<any>;

  @Input() public sendButtonDisabled: boolean;
  @Input() public previewButtonDisabled: boolean;
  @Input() public cancelButtonDisabled: boolean;
  @Input() public generateEMER2ButtonShow: boolean;
  @Input() public ackEMER1ButtonDisabled: boolean;
  @Input() public ackEMER2ButtonDisabled: boolean;
  @Input() public confirmEMER2ButtonDisabled: boolean;

  @Input() public ackEMER1ButtonShow: boolean;
  @Input() public ackEMER2ButtonShow: boolean;
  @Input() public confirmEMER2ButtonShow: boolean;

  @Input() public sendButtonShow: boolean;
  @Input() public isEmer1: boolean;
  @Input() public alertEmer: AlertEMER;

  @Output() public send: EventEmitter<object> = new EventEmitter<object>(); // creating an output event
  @Output() public preview: EventEmitter<object> = new EventEmitter<object>(); // creating an output event
  @Output() public close: EventEmitter<object> = new EventEmitter<object>(); // creating an output event
  @Output() public generateEMER2: EventEmitter<object> = new EventEmitter<object>(); // creating an output event
  @Output() public ackEMER1: EventEmitter<object> = new EventEmitter<object>(); // creating an output event
  @Output() public ackEMER2: EventEmitter<object> = new EventEmitter<object>(); // creating an output event
  @Output() public confirmEMER2: EventEmitter<object> = new EventEmitter<object>(); // creating an output event

  constructor(private modalService: BsModalService) { }

  sendButton() {
    this.openModal();
  }
  previewButton() { this.preview.emit(); }
  closeButton() { this.close.emit(); }
  generateEMER2Button() { this.generateEMER2.emit(); }
  ackEMER1Button() { this.openModal() }
  ackEMER2Button() { this.openModal() }
  confirmEMER2Button() { this.openModal() }

  confirmAckEMER1() { this.closeModal(); this.ackEMER1.emit() }
  confirmAckEMER2() { this.closeModal(); this.ackEMER2.emit() }
  confirmSend() { this.closeModal(); this.send.emit(); }
  confirmConfirmEMER2Button() {  this.closeModal(); this.confirmEMER2.emit(); }

  openModal() {
    // console.log(this.alertEmer);
    this.modalRef = this.modalService.show(this.templateConfirm);
  }
  closeModal() {this.modalRef.hide() }

  generateArray(obj) {
    // console.log(this.getAllKeyFromAlert(obj));
    return Object.keys(obj).map((key) => {
      // console.log(key);
      // console.log(obj);
      // console.log(typeof (obj[key]));
      return { key: key, value: JSON.stringify(obj[key]) }
    });
  }
}
