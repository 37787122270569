import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { FaxEMER1, FaxEMER2 } from '../../models/fax'
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Rx';
import { AuthService } from '../../services/auth.service';
import { saveAs } from 'file-saver/FileSaver';
import { Alert, SEVERITY, CERTAINTY, URGENCY, CATEGORY_BY_EVENTTYPE } from '../../models/alert'
import { AlertEMER, RECIPIENT, RESOURCE, Resource, Reference } from '../../models/alertEMER'

const baseUrl = environment.dispNgEmerFaxApi.baseUrl;
const previewApi = environment['dispNgEmerFaxApi']['previewPOST'];
const sendApi = environment['dispNgEmerFaxApi']['sendPOST'];
const statusApi = environment['dispNgEmerFaxApi']['statusGET'];

const baseCreationFaxUrl = environment.dispNgEmerApi.baseUrl;
const createFaxApi = environment['dispNgEmerApi']['GET']['faxes'];

@Injectable()
export class FaxService {
    private RESOURCE = RESOURCE;

    constructor(private http: HttpClient, private authService: AuthService) { }

    createFaxIncident(incidentId: string) {
        const url: string = baseCreationFaxUrl + createFaxApi + '/' + incidentId;

        return this.http.get(url, {
                headers: {
                    'Accept': 'application/pdf',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.authService.getAccessToken()
                },
                responseType: 'blob'
            }
        )
        // ...and calling .json() on the response to return data
        .map((res) => res)
        // ...errors if any
        .catch((error: any) => Observable.throw(error || 'Server error'));
    }

    preview(fax: any) {
        const url: string = baseUrl + previewApi;

        return this.http.post(url, fax, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },
            responseType: 'blob'
        }
        )
        // ...and calling .json() on the response to return data
        .map((res) => res)
        // ...errors if any
        .catch((error: any) => Observable.throw(error || 'Server error'));
    }

    sendAlertEmer(fax: any) {
        const url: string = baseUrl + sendApi;
        return this.http.post(url, fax, {
            headers: {
                'Content-Type': 'application/json',

                'Accept': 'application/pdf'
            },
            responseType: 'blob'
        })
            .map((res) => res)
            .catch((error: any) => Observable.throw(error || 'Server error'));
    }

    alertEMERtoFax(alertEmer: AlertEMER) { return null; }
    createFaxEMER1Object(alertEMER: AlertEMER) {
        // alertEMER.removeEmptyRessources();
        if (this.authService.getUser() !== null) {
            const date = new Date();
            const lat = alertEMER.getLatCenter();
            const lng = alertEMER.getLngCenter();
            const coord = this.ddToDms(lat, lng);

            return new FaxEMER1(
                'false', // isItaly
                '', // idDoc
                alertEMER.alert.getcustomProperty('city').value,
                '',
                alertEMER.alert.description,
                alertEMER.alert.getcustomProperty('locality').value,
                'X', // isInfo
                '', // isRequest
                '', // isDrill
                alertEMER.alert.eventType === 'Landslide' ? 'X' : '',
                alertEMER.alert.eventType === 'Flood' ? 'X' : '',
                alertEMER.alert.eventType === 'Earthquake' ? 'X' : '',
                alertEMER.alert.eventType === 'Forest Fire' ? 'X' : '',
                alertEMER.alert.eventType === 'Other' ? 'X' : '',
                this.authService.getUser().firstname + ' ' + this.authService.getUser().lastname, // fromOperatorName
                'PALMANOVA', // from
                'LJUBLJANA', // to
                '', // toDate
                coord[0], // geoNorth
                coord[1], // geoSouth
                this.getCurrentDay(date) + '/' + this.getCurrentMonth(date) + '/' + this.getCurrentYear(date) +
                ' h ' + this.getCurrentHours(date) + ':' + this.getCurrentMinutes(date), // fromDate
                '', // isOtherComment
                '', // mi
                '', // toOperatorName
                alertEMER.recipients.indexOf(RECIPIENT['POSTOJNA']) >= 0 ? 'X' : '', // isPOSTOJNA
                alertEMER.recipients.indexOf(RECIPIENT['NOVA-GORICA']) >= 0 ? 'X' : '', // isNOVAGORICA
                alertEMER.recipients.indexOf(RECIPIENT['KOPER']) >= 0 ? 'X' : '', // isKOPER
                alertEMER.recipients.indexOf(RECIPIENT['KRANJ']) >= 0 ? 'X' : '', // isKRANJ
                '', // toNote
                '', // descriptionInfo
                '', // descriptionRequest
                '', // descriptionDrill
            );
        } else { return null };
    }


    createFaxEMER2Object(alertEMER: AlertEMER, alertEMERoffer: AlertEMER) {

        /// *******************************************
        /// *******************************************
        /// GET ALL RESOURCE NOT JUST [0] !!! TODO !!!!!
        /// *******************************************
        /// *******************************************

        alertEMER.removeEmptyRessources();
        if (this.authService.getUser() !== null) {
            const date = new Date();
            const lat = alertEMER.getLatCenter();
            const lng = alertEMER.getLngCenter();
            const coord = this.ddToDms(lat, lng);
            if (!alertEMERoffer) {
                const alert = new Alert({
                    author: '',
                    category: CATEGORY_BY_EVENTTYPE.Other,
                    certainty: CERTAINTY.UNKNOWN_CERTAINTY,
                    customProperties: [
                        { key: 'city', value: '' },
                        { key: 'locality', value: '' },
                        { key: 'other', value: '' },
                        { key: 'operator', value: '' }
                    ],
                    description: '',
                    eventType: '',
                    headline: '',
                    severity: SEVERITY.UNKNOWN_SEVERITY,
                    status: 'BROKEN',
                    urgency: URGENCY.UNKNOWN_URGENCY,
                });
                const ressourcesArray = new Array<Resource>();
                alertEMERoffer = new AlertEMER({
                    alert: alert,
                    area: {
                    },
                    key: null,
                    recipients: new Array<RECIPIENT>(),
                    resources: ressourcesArray
                });

            }
            return new FaxEMER2(
                'false', // public isItaly: String,
                '', // public idDoc: String,
                alertEMER.alert.getcustomProperty('city').value, // public city: String,
                '', // public comuneNum: String,
                alertEMER.alert.getcustomProperty('locality').value, // public locality: String,
                alertEMER.recipients.indexOf(RECIPIENT['POSTOJNA']) >= 0 ? 'X' : '', // public isPROSNJA: String,
                '', // public isVAJA: String,
                alertEMER.alert.eventType === 'Landslide' ? 'X' : '', // public isLandslide: String,
                alertEMER.alert.eventType === 'Flood' ? 'X' : '', // public isFlood: String,
                alertEMER.alert.eventType === 'Earthquake' ? 'X' : '', // public isEarthquake: String,
                alertEMER.alert.eventType === 'Other' ? 'X' : '', // public isOther: String,
                coord[0], // public geoNorth: String,
                coord[1], // public geoSouth: String,
                '', // public mi: String,
                this.authService.getUser().firstname + ' ' + this.authService.getUser().lastname, // public toOperatorName: String,
                this.authService.getUser().firstname + ' ' + this.authService.getUser().lastname, // toOperatorNameRequest
                '', // public toOperatorNameOffer: String,
                alertEMER.recipients.indexOf(RECIPIENT['POSTOJNA']) >= 0 ? 'X' : '', // isPOSTOJNA
                alertEMER.recipients.indexOf(RECIPIENT['NOVA-GORICA']) >= 0 ? 'X' : '', // isNOVAGORICA
                alertEMER.recipients.indexOf(RECIPIENT['KOPER']) >= 0 ? 'X' : '', // isKOPER
                alertEMER.recipients.indexOf(RECIPIENT['KRANJ']) >= 0 ? 'X' : '', // isKRANJ
                '', // public toNote: String,
                this.getCurrentDay(date) + '/' + this.getCurrentMonth(date) + '/' + this.getCurrentYear(date), // dateDay
                this.getCurrentHours(date) + ':' + this.getCurrentMinutes(date), // public hourDay: String,
                alertEMER.getResources(this.RESOURCE['PERSONNEL'])[0].number ?
                    alertEMER.getResources(this.RESOURCE['PERSONNEL'])[0].number +
                    ' ' + alertEMER.getResources(this.RESOURCE['PERSONNEL'])[0].capabilities[0] : '', // personnelRequest
                alertEMERoffer.getResources(this.RESOURCE['PERSONNEL'])[0].number ?
                    alertEMERoffer.getResources(this.RESOURCE['PERSONNEL'])[0].number +
                    ' ' + alertEMERoffer.getResources(this.RESOURCE['PERSONNEL'])[0].capabilities[0] : '', // public personnelOffer: String,
                alertEMER.getResources(this.RESOURCE['VEHICLES'])[0].number ?
                    alertEMER.getResources(this.RESOURCE['VEHICLES'])[0].number +
                    ' ' + alertEMER.getResources(this.RESOURCE['VEHICLES'])[0].capabilities[0] : '', // vehiclesRequest
                alertEMERoffer.getResources(this.RESOURCE['VEHICLES'])[0].number ?
                    alertEMERoffer.getResources(this.RESOURCE['VEHICLES'])[0].number +
                    ' ' + alertEMERoffer.getResources(this.RESOURCE['VEHICLES'])[0].capabilities[0] : '', // public vehiculesOffer: String,
                alertEMER.getResources(this.RESOURCE['FOOD'])[0].number ?
                    alertEMER.getResources(this.RESOURCE['FOOD'])[0].number +
                    ' ' + alertEMER.getResources(this.RESOURCE['FOOD'])[0].capabilities[0] : '', // foodRequest
                alertEMERoffer.getResources(this.RESOURCE['FOOD'])[0].number ?
                    alertEMERoffer.getResources(this.RESOURCE['FOOD'])[0].number +
                    ' ' + alertEMERoffer.getResources(this.RESOURCE['FOOD'])[0].capabilities[0] : '', // public foodOffer: String,
                alertEMER.getResources(this.RESOURCE['TENTS'])[0].number ?
                    alertEMER.getResources(this.RESOURCE['TENTS'])[0].number +
                    ' ' + alertEMER.getResources(this.RESOURCE['TENTS'])[0].capabilities[0] : '', //  tentsRequest
                alertEMERoffer.getResources(this.RESOURCE['TENTS'])[0].number ?
                    alertEMERoffer.getResources(this.RESOURCE['TENTS'])[0].number +
                    ' ' + alertEMERoffer.getResources(this.RESOURCE['TENTS'])[0].capabilities[0] : '', // public tentsOffer: String,
                alertEMER.getResources(this.RESOURCE['HELI'])[0].number ?
                    alertEMER.getResources(this.RESOURCE['HELI'])[0].number +
                    ' ' + alertEMER.getResources(this.RESOURCE['HELI'])[0].capabilities[0] : '', //  heliRequest
                alertEMERoffer.getResources(this.RESOURCE['HELI'])[0].number ?
                    alertEMERoffer.getResources(this.RESOURCE['HELI'])[0].number +
                    ' ' + alertEMERoffer.getResources(this.RESOURCE['HELI'])[0].capabilities[0] : '', // public heliOffer: String,
                '', // public localisation: String,
                alertEMER.getResources(this.RESOURCE['OFFICER'])[0].number ?
                    alertEMER.getResources(this.RESOURCE['OFFICER'])[0].number +
                    ' ' + alertEMER.getResources(this.RESOURCE['OFFICER'])[0].capabilities[0] : '', //  officerRequest
                alertEMERoffer.getResources(this.RESOURCE['OFFICER'])[0].number ?
                    alertEMERoffer.getResources(this.RESOURCE['OFFICER'])[0].number +
                    ' ' + alertEMERoffer.getResources(this.RESOURCE['OFFICER'])[0].capabilities[0] : '', // public officerOffer: String,
                alertEMER.getResources(this.RESOURCE['INTERPRETER'])[0].number ?
                    alertEMER.getResources(this.RESOURCE['INTERPRETER'])[0].number +
                    ' ' + alertEMER.getResources(this.RESOURCE['INTERPRETER'])[0].capabilities[0] : '', // interpreterRequest
                alertEMERoffer.getResources(this.RESOURCE['INTERPRETER'])[0].number ?
                    alertEMERoffer.getResources(this.RESOURCE['INTERPRETER'])[0].number +
                    ' ' + alertEMERoffer.getResources(this.RESOURCE['INTERPRETER'])[0].capabilities[0] : '',
                // public interpreterOffer: String,
                alertEMER.getResources(this.RESOURCE['NOTES'])[0].number ?
                    alertEMER.getResources(this.RESOURCE['NOTES'])[0].number +
                    ' ' + alertEMER.getResources(this.RESOURCE['NOTES'])[0].capabilities[0] : '', // notesRequest
                alertEMERoffer.getResources(this.RESOURCE['NOTES'])[0].number ?
                    alertEMERoffer.getResources(this.RESOURCE['NOTES'])[0].number +
                    ' ' + alertEMERoffer.getResources(this.RESOURCE['NOTES'])[0].capabilities[0] : '', // public notesOffer: String,
                this.getCurrentDay(date) + '/' + this.getCurrentMonth(date) + '/' + this.getCurrentYear(date), // public dateOperation: String,
                this.getCurrentHours(date) + ':' + this.getCurrentMinutes(date), // public hourOperation: String,
                '', // public dateOperator: String,
                '', // public hourOperator: String,
                '', // public message: String,
                alertEMER.getResources(this.RESOURCE['MEETING_POINT'])[0].number ?
                    alertEMER.getResources(this.RESOURCE['MEETING_POINT'])[0].number.toString()
                    + ' ' + alertEMER.getResources(this.RESOURCE['MEETING_POINT'])[0].capabilities[0] : '', //  meetingpointRequest
                alertEMERoffer.getResources(this.RESOURCE['MEETING_POINT'])[0].number ?
                    alertEMERoffer.getResources(this.RESOURCE['MEETING_POINT'])[0].number +
                    ' ' + alertEMERoffer.getResources(this.RESOURCE['MEETING_POINT'])[0].capabilities[0] : '',
                // public meetingpointOffer: String,
                alertEMER.getResources(this.RESOURCE['OPERATOR'])[0].number ?
                    alertEMER.getResources(this.RESOURCE['OPERATOR'])[0].number.toString() +
                    ' ' + alertEMER.getResources(this.RESOURCE['OPERATOR'])[0].capabilities[0] : '', // operatorRequest
                alertEMERoffer.getResources(this.RESOURCE['OPERATOR'])[0].number ?
                    alertEMERoffer.getResources(this.RESOURCE['OPERATOR'])[0].number +
                    ' ' + alertEMERoffer.getResources(this.RESOURCE['OPERATOR'])[0].capabilities[0] : '', // public operatorOffer: String,
                alertEMER.getResources(this.RESOURCE['RADIO_CHANEL'])[0].number ?
                    alertEMER.getResources(this.RESOURCE['RADIO_CHANEL'])[0].number.toString()
                    + ' ' + alertEMER.getResources(this.RESOURCE['RADIO_CHANEL'])[0].capabilities[0] : '', // radiochannelRequest
                alertEMERoffer.getResources(this.RESOURCE['RADIO_CHANEL'])[0].number ?
                    alertEMERoffer.getResources(this.RESOURCE['RADIO_CHANEL'])[0].number +
                    ' ' + alertEMERoffer.getResources(this.RESOURCE['RADIO_CHANEL'])[0].capabilities[0] : '',
                // public radiochannelOffer: String,
            );
        } else { return null };
    }


    // This function returns the coordinate
    // conversion string in DD to DMS.
    ddToDms(lat, lng) {
        let latResult, lngResult, dmsResult;
        lat = parseFloat(lat);
        lng = parseFloat(lng);

        latResult = (lat >= 0) ? 'N ' : 'S ';
        // Call to getDms(lat) function for the coordinates of Latitude in DMS.
        // The result is stored in latResult variable.
        latResult += this.getDms(lat);
        lngResult = (lng >= 0) ? 'E ' : 'W ';
        // Call to getDms(lng) function for the coordinates of Longitude in DMS.
        // The result is stored in lngResult variable.
        lngResult += this.getDms(lng);
        // Joining both variables and separate them with a space.
        dmsResult = latResult + ' ' + lngResult;
        // Return the resultant string
        return [latResult, lngResult];
    }

    getDms(val) {
        let valDeg, valMin, valSec, result;
        val = Math.abs(val);
        valDeg = Math.floor(val);
        result = valDeg + 'º ';
        valMin = Math.floor((val - valDeg) * 60);
        result += valMin + '\' ';
        valSec = Math.round((val - valDeg - valMin / 60) * 3600 * 1000) / 1000;
        result += valSec + '\'\'';
        return result;
    }



    displayAndSavePDF(msg) {

        const blob = new Blob([msg], {
            type: 'application/pdf'
        });
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
        saveAs(blob, 'faxPreview');
    }

    getCurrentDay(date: Date): string {
        return date.getDate().toString();
    }

    getCurrentMonth(date: Date): string {
        return (date.getMonth() + 1).toString();
    }

    getCurrentYear(date: Date): string {
        return date.getFullYear().toString();
    }

    getCurrentHours(date: Date): string {
        return date.getHours().toString();
    }

    getCurrentMinutes(date: Date): string {
        return date.getMinutes().toString();
    }
}
