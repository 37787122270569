import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Rx';
import OlVector from 'ol/source/vector';
import OlFeature from 'ol/feature';
import OlPolygon from 'ol/geom/polygon';
import OlPoint from 'ol/geom/point';
import OlCircle from 'ol/geom/circle';

import Fill from 'ol/style/fill';
import Stroke from 'ol/style/stroke';
import Text from 'ol/style/text';
import Icon from 'ol/style/icon';

import Style from 'ol/style/style';

import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service'
// Import RxJs required methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { AlertEMER, EVENT_TYPE_COLOR, RECIPIENT } from '../../models/alertEMER';
import { Incident } from '../../models/incident';

const baseUrl = environment.baseUrl;
const dispNgEmerApi = environment.dispNgEmerApi.baseUrl;
const informEndpoint = environment['dispNgEmerApi']['POST']['inform'];
const acknowledgeEndpoint = environment['dispNgEmerApi']['PUT']['ack'];
const requestEndpoint = environment['dispNgEmerApi']['PUT']['request'];
const offerEndpoint = environment['dispNgEmerApi']['PUT']['offer'];
const confirmEndpoint = environment['dispNgEmerApi']['PUT']['confirm'];



const incidentsEndpoint = environment['dispNgEmerApi']['GET']['incidents'];
const messagesEndpoint = environment['dispNgEmerApi']['GET']['messages'];
const incidentDeleteEndpoint = environment['dispNgEmerApi']['CLOSE']['incident'];

const recos = environment['RECOs'];
@Injectable()
export class EmerService {
    public recipients: String[] = Object.keys(RECIPIENT);

    constructor(private http: HttpClient, private authService: AuthService) { }
    // curl -H "Authorization: Bearer $TOKEN" localhost:9999/uaa/user2  | jq '.'
    inform(alertEMER: AlertEMER): any {
        const url: string = dispNgEmerApi + informEndpoint;
        alertEMER.removeEmptyRessources();
        alertEMER.removeEmptyCustomProperties();

        return this.http.post(url, alertEMER, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.authService.getAccessToken(),
            }
        })
            .timeout(5000)
            // ...and calling .json() on the response to return data
            .map((res: Response) => res)
            // ...errors if any
            .catch((error: any) => (Observable.throw(error || 'Server error')));
    }

    request(alertEMER: AlertEMER): any {
        const url: string = dispNgEmerApi + requestEndpoint;
        alertEMER.removeEmptyRessources();
        alertEMER.removeEmptyCustomProperties();
        return this.http.put(url, alertEMER, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.authService.getAccessToken(),
            }
        })
            .timeout(5000)
            // ...and calling .json() on the response to return data
            .map((res: Response) => res)
            // ...errors if any
            .catch((error: any) => (Observable.throw(error || 'Server error')));
    }


    acknowledge(alertEMER: AlertEMER) {
        const url: string = dispNgEmerApi + acknowledgeEndpoint;
        return this.http.put(url, alertEMER, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.authService.getAccessToken(),
            }
        })
            .timeout(5000)
            // ...and calling .json() on the response to return data
            .map((res: Response) => res)
            // ...errors if any
            .catch((error: any) => (Observable.throw(error || 'Server error')));
    }


    confirm(alertEMER: AlertEMER) {
        const url: string = dispNgEmerApi + confirmEndpoint;
        return this.http.put(url, alertEMER, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.authService.getAccessToken(),
            }
        })
            .timeout(5000)
            // ...and calling .json() on the response to return data
            .map((res: Response) => res)
            // ...errors if any
            .catch((error: any) => (Observable.throw(error || 'Server error')));
    }

    offer(alertEMER: AlertEMER): any {
        const url: string = dispNgEmerApi + offerEndpoint;
        alertEMER.removeEmptyRessources();
        alertEMER.removeEmptyCustomProperties();

        return this.http.put(url, alertEMER, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.authService.getAccessToken(),
            }
        })
            .timeout(5000)
            // ...and calling .json() on the response to return data
            .map((res: Response) => res)
            // ...errors if any
            .catch((error: any) => (Observable.throw(error || 'Server error')));
    }


    getCenter(arrayCoord) {


        let latAverage = 0;
        let lngAverage = 0;
        let minLat;
        let maxLat;
        let minLng;
        let maxLng;
        for (const coord of arrayCoord) {
            if (!minLat || minLat > coord[1]) {

                minLat = coord[1];
            }
            if (!minLng || minLng > coord[0]) {

                minLng = coord[0];
            }
            if (!maxLat || maxLat < coord[1]) {

                maxLat = coord[1];
            }
            if (!maxLng || maxLng < coord[0]) {

                maxLng = coord[0];
            }
            latAverage += coord[1];
            lngAverage += coord[0];
        }
        lngAverage = lngAverage / arrayCoord.length
        latAverage = latAverage / arrayCoord.length

        return [(minLng + maxLng) / 2, (minLat + maxLat) / 2]

        // return [lngAverage, latAverage];
    }

    alertToVectorSource(alertEMER: AlertEMER, incident: Incident) {
        // console.log('alertToVectorSource');

        const vectorSource = new OlVector();
        let featureType;
        let iconCoord;
        if (incident) {
            if (incident.area.polygons) {
                if (incident.area.polygons.length > 0) {
                    const arrayCoord = new Array();
                    for (const poly of incident.area.polygons) {
                        for (const coord of poly.coordinates) {
                            arrayCoord.push([coord.lng, coord.lat]);
                        }
                    }
                    featureType = new OlPolygon([arrayCoord]);
                    featureType.transform('EPSG:4326', 'EPSG:3857');
                    iconCoord = this.getCenter(featureType.getCoordinates()[0]);
                }
            } else if (alertEMER.area.circles) {
                if (incident.area.circles.length > 0) {
                    const arrayCoord = new Array();
                    const circleAlert = incident.area.circles[0];
                    arrayCoord.push(circleAlert.center.lng);
                    arrayCoord.push(circleAlert.center.lat);
                    arrayCoord.push(0);
                    featureType = new OlCircle(arrayCoord, circleAlert.radius);
                    featureType.transform('EPSG:4326', 'EPSG:3857');
                    iconCoord = featureType.getCenter();

                }
            }
        }
        if (alertEMER) {
            const feature = new OlFeature(featureType);
            feature.setStyle(new Style({
                stroke: new Stroke({
                    color: 'rgba(' + EVENT_TYPE_COLOR[alertEMER.alert.eventType.replace(' ', '')] + ')',
                    width: 1
                }),
                fill: new Fill({
                    color: 'rgba(' + EVENT_TYPE_COLOR[alertEMER.alert.eventType.replace(' ', '')] + ', 0.1)'
                }),
                text: new Text({
                    textAlign: 'center',
                    textBaseline: 'middle',
                    font: '14px Verdana',
                    text: alertEMER.alert.headline,
                    fill: new Fill({ color: 'rgba(' + EVENT_TYPE_COLOR[alertEMER.alert.eventType.replace(' ', '')] + ')' }),
                    // stroke: new Stroke({ color: '#ffffff', width: 3 }),
                    offsetX: 0,
                    offsetY: 0,
                    placement: 'point',
                    maxAngle: 0.7853981633974483,
                    overflow: true,
                    rotation: 0
                }),

            }));


            const point = new OlPoint(iconCoord);
            // point.transform('EPSG:4326', 'EPSG:3857');
            const iconFeature = new OlFeature({
                geometry: point,
            });
            const iconStyle = new Style({
                image: new Icon(/** @type {module:ol/style/Icon~Options} */({
                    anchor: [1, 1.5],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'fraction',
                    src: '/assets/img/' + alertEMER.alert.eventType.replace(' ', '') + '.png'
                }))
            });
            iconFeature.setStyle(iconStyle);

            iconFeature.set('key', alertEMER.key);
            iconFeature.set('alertEMER', alertEMER);

            iconFeature.set('area', incident.area);

            feature.set('key', alertEMER.key);
            feature.set('alertEMER', alertEMER);
            feature.set('area', incident.area);

            if (incident) {
                feature.set('incident', incident);
                iconFeature.set('incident', incident);
            }

            vectorSource.addFeature(feature);
            vectorSource.addFeature(iconFeature);
        }
        return vectorSource;
    }

    getIncidents() {
        const url: string = dispNgEmerApi + incidentsEndpoint;
        return this.http.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.authService.getAccessToken(),
            }
        })
            .timeout(environment.timeout)
            // ...and calling .json() on the response to return data
            .map((res: Incident[]) => res)
            // ...errors if any
            .catch((error: any) => (
                Observable.throw(error.message || 'Server error')
                )
            );
    }

    getAlertsByIncident(incidentId: string) {
        const url: string = dispNgEmerApi + messagesEndpoint.replace('$1', incidentId);
        return this.http.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.authService.getAccessToken(),
            }
        })
            .timeout(environment.timeout)
            // ...and calling .json() on the response to return data
            .map((res: AlertEMER[]) => { return res })
            // ...errors if any
            .catch((error: any) => (Observable.throw(error || 'Server error')));
    }


    deleteIncident(incidentId: string) {
        const headers = new HttpHeaders();
        headers.append('Authorization', 'Bearer ' + this.authService.getAccessToken());
        const url: string = dispNgEmerApi + incidentDeleteEndpoint.replace('$1', incidentId);
        console.log(url);
        return this.http.delete(url, {
            headers: {
                'Content-Type': 'application/json',

                'Authorization': 'Bearer ' + this.authService.getAccessToken(),
            }
        })
            .timeout(environment.timeout)
            // ...and calling .json() on the response to return data
            .map((res: Response) => { console.log(res); return res })
            // ...errors if any
            .catch((error: any) => (Observable.throw(error || 'Server error')));
    }

    isReco() {
        // to know if is a ReCo or not and display the correct recipients
        for (const orga of this.authService.getUser().organizations) {
            if (recos.indexOf(orga) === -1) { return false; }
        }
        return true;
    }

    isForAck(alerEmer: AlertEMER) {
        for (const orga of this.authService.getUser().organizations) {
            if (alerEmer.recipients.indexOf(this.recipients['orga']) !== -1) { return true; }
        }
    }
}
